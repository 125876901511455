import {
    IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonGrid, IonHeader,
    IonLoading, IonPage, IonSelect, IonSelectOption, IonSpinner,
    IonTextarea, IonTitle, IonToolbar
} from '@ionic/react';
import { signOut } from 'firebase/auth';
import React from 'react';
import { connect } from 'react-redux';
import MenuTabBar from '../../../common/TabBar/MenuTabBar';
import { auth, functions } from '../../../firebaseConfig';
import { authActions } from '../../authentication/store/actions';
import { httpsCallable } from 'firebase/functions';
import "./Profile.css";
/* import { get, ref } from 'firebase/database'; */

type Props = {
    history: any,

    logout: () => void,
};

type State = {
    userEmail: string | null,
    pendingLicenses: number | null,
    redeemedLicenses: number | null,
    totalLicenses: number | null,
    isLoadingLeads: boolean,

    requestType: number | null,
    requestText: string | null,
    requestToManagementSent: boolean,
    requestLoading: boolean,
};

class Profile extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            userEmail: null,

            pendingLicenses: null,
            redeemedLicenses: null,
            totalLicenses: null,
            isLoadingLeads: false,

            requestType: null,
            requestText: null,
            requestToManagementSent: false,
            requestLoading: false,
        }
    }

    componentDidMount(): void {
        const getEmployeeLeads = httpsCallable(functions, "getEmployeeLeads");
        auth.onAuthStateChanged(userData => {
            if(userData) {
                this.setState({
                    userEmail: userData.email,
                    //isLoadingLeads: true,
                });

                /* get(ref(database, `u/${userData.uid}/i`))
                .then(data => {
                    if(data.val()) {
                        getEmployeeLeads({
                            employeeId: data.val(),
                        })
                        .then((res) => {
                            console.log("[Profile] getEmployeeLeads response:", res.data);
                            if(res.data)
                            this.setState({
                                pendingLicenses: (res.data as any).pendingLicenses,
                                redeemedLicenses: (res.data as any).redeemedLicenses,
                                totalLicenses: (res.data as any).totalLicenses,
                                isLoadingLeads: false,
                            });
                        })
                        .catch(err => {
                            console.error("[Profile] error calling getEmployeeLeads function:", err);
                            this.setState ({
                                pendingLicenses: null,
                                redeemedLicenses: null,
                                totalLicenses: null,
                                isLoadingLeads: false,
                            })
                        });
                    }
                })
                .catch(err => {
                    console.error("[Profile] error getting employee id:", err);
                    this.setState({
                        isLoadingLeads: false,
                    })
                }); */
            }
            else {
                this.setState({
                    userEmail: null,
                    isLoadingLeads: false,
                });
            }
        });

        
        
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            Profile
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="profileMainGrid">
                        {
                            this.state.userEmail &&
                            <p className="profileUserEmailParagraph">
                                <h2>
                                    {this.state.userEmail}
                                </h2>
                            </p>
                        }

                        {
                            this.state.isLoadingLeads &&
                            <div>
                                <IonSpinner />
                            </div>
                        }

                        {
                            !this.state.requestToManagementSent &&
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle>
                                        Send request to management team 📆
                                    </IonCardTitle>
                                </IonCardHeader>

                                <IonCardContent>
                                    <IonSelect
                                        value={this.state.requestType}
                                        placeholder="Kind of request"
                                        onIonChange={e => {
                                            this.setState({ requestType: e.detail.value});
                                        }}
                                    >
                                        <IonSelectOption value={1}>
                                            Remote work 🧑‍💻 {/* TO BE LOCALIZED */}
                                        </IonSelectOption>
                                        <IonSelectOption value={2}>
                                            Leave 👶🏻{/* TO BE LOCALIZED */}
                                        </IonSelectOption>
                                        <IonSelectOption value={3}>
                                            Vacation 🏝️{/* TO BE LOCALIZED */}
                                        </IonSelectOption>
                                        <IonSelectOption value={4}>
                                            Sick 🤒 {/* TO BE LOCALIZED */}
                                        </IonSelectOption>
                                        <IonSelectOption value={5}>
                                            Other {/* TO BE LOCALIZED */}
                                        </IonSelectOption>
                                    </IonSelect>
                                    <IonTextarea
                                        value={this.state.requestText}
                                        placeholder="Write the period and some other details to help us evaluate the request"
                                        onIonChange={e => {
                                            this.setState({ requestText: e.detail.value ? e.detail.value : null });
                                        }}
                                    />
                                    <div className="profileSendRequestButtonDiv">
                                        <IonButton
                                            disabled={
                                                !this.state.requestType ||
                                                !this.state.requestText
                                            }
                                            onClick={() => {
                                                const requestToManagement = httpsCallable(functions, "requestToManagement");
                                                
                                                this.setState({requestLoading: true});
                                                requestToManagement({
                                                    requestType: this.state.requestType,
                                                    requestText: this.state.requestText,
                                                })
                                                .then((res) => {
                                                    console.log("[Profile] requestToManagement response:", res.data);
                                                    if(res.data)
                                                    this.setState({
                                                        requestToManagementSent: true,
                                                        requestType: null,
                                                        requestText: null,
                                                        requestLoading: false,
                                                    });
                                                })
                                                .catch(err => {
                                                    console.error("[Profile] error calling requestToManagement function:", err);
                                                    this.setState ({
                                                        requestToManagementSent: false,
                                                        requestLoading: false,
                                                    })
                                                });
                                            }}
                                        >
                                            Send request to management team {/* TO BE LOCALIZED */}
                                        </IonButton>
                                    </div>
                                </IonCardContent>
                            </IonCard>
                        }

                        {
                            this.state.requestToManagementSent &&
                            <IonCard>
                                <IonCardContent>
                                    <p>Request sent.</p>
                                    <p>The management team will give a clear acknowledgement by email.</p>
                                </IonCardContent>
                            </IonCard>
                        }

                        {/* {
                            (this.state.pendingLicenses || this.state.redeemedLicenses || this.state.totalLicenses) &&
                            <div className="profileLeadsMainDiv">
                                <div className="profileLeadsFieldDiv">
                                    <p>Pending licenses:</p>
                                    <p className="profileLeadsNumberParagraph">
                                        {this.state.pendingLicenses}
                                    </p>
                                </div>
                                <div className="profileLeadsFieldDiv">
                                    <p>Redeemed licenses:</p>
                                    <p className="profileLeadsNumberParagraph">
                                        {this.state.redeemedLicenses}
                                    </p>
                                </div>
                                <div className="profileLeadsFieldDiv">
                                    <p><b>Total licenses*:</b></p>
                                    <p className="profileLeadsNumberParagraph">
                                        <b>{this.state.totalLicenses}</b>
                                    </p>
                                </div>
                                <p className='profileLeadsDislaimer'>
                                    * Any duplication in leads will be removed for commission calculation.
                                </p>
                            </div>
                        } */}

                        <IonButton
                            className="profileLogoutButton"
                            onClick={() => {
                                signOut(auth)
                                .then(() => {
                                    this.props.logout()
                                    setTimeout(() => {
                                        this.props.history.replace("/");
                                    }, 900);
                                })
                                .catch(err => {
                                    console.error("[Profile] error logging out:", err);
                                })
                            }}
                        >
                            Logout
                        </IonButton>
                    </IonGrid>
                </IonContent>

                <MenuTabBar />

                <IonLoading 
                    isOpen={this.state.requestLoading}
                />
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        logout: () => {
            dispatch(authActions.logout());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);