import {
    IonButton, IonContent, IonGrid, IonInput, IonItem,
    IonLoading, IonPage, IonSelect, IonSelectOption,
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import { functions } from '../../../firebaseConfig';
import { authActions } from '../../authentication/store/actions';
import greenRoundCheckmark from "../../../assets/images/greenRoundCheckmark.png";
import "./Event.css";


type Props = {
    history: any,
};

type State = {
    name: string | null,
    surname: string | null,
    email: string | null,
    role: string | null,
    roleString: string | null,
    schoolName: string | null,
    city: string | null,
    accountManager: number | null,

    isLoading: boolean,
    isSent: boolean,
};

class Event extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            name: null,
            surname: null,
            email: null,
            role: null,
            roleString: null,
            schoolName: null,
            city: null,
            accountManager: null,

            isLoading: false,
            isSent: false,
        };
    }


    render() {
        return (
            <IonPage>
                <IonContent className="eventContent">
                    <IonGrid className="eventMainGrid">
                        <div className="eventFormDiv">

                            {
                                this.state.isSent &&
                                <>
                                    <div className="eventSentDiv">
                                        <h2 className="eventTitleH2">
                                            Licenza inviata! Controlla la tua email. {/* TO BE LOCALIZED */}
                                        </h2>

                                        <img
                                            className="eventSentImg"
                                            src={greenRoundCheckmark}
                                            alt="check mark"
                                        />
                                    </div>
                                </>
                            }

                            {
                                !this.state.isSent &&
                                <>
                                    <h2 className="eventTitleH2">
                                        Ottieni 3 mesi di licenza gratuita
                                    </h2>

                                    <div className="eventInputDiv">
                                        <IonInput
                                            className="eventInput"
                                            value={this.state.name}
                                            placeholder="Nome"
                                            onIonChange={e => {
                                                this.setState({ name: e.detail.value ? e.detail.value : null});
                                            }}
                                        />
                                    </div>

                                    <div className="eventInputDiv">
                                        <IonInput
                                            className="eventInput"
                                            value={this.state.surname}
                                            placeholder="Cognome"
                                            onIonChange={e => {
                                                this.setState({ surname: e.detail.value ? e.detail.value : null});
                                            }}
                                        />
                                    </div>
                                    
                                    <div className="eventInputDiv">
                                        <IonInput
                                            className="eventInput"
                                            value={this.state.email}
                                            placeholder="Email"
                                            type="email"
                                            onIonChange={e => {
                                                this.setState({ email: e.detail.value ? e.detail.value : null});
                                            }}
                                        />
                                    </div>

                                    <div className="eventInputDiv">
                                        <IonSelect
                                            className="eventInput"
                                            value={this.state.role}
                                            placeholder="Ruolo" /* TO BE LOCALIZED */
                                            onIonChange={e => {
                                                this.setState({ role: e.detail.value});
                                            }}
                                        >
                                            <IonItem>
                                                <IonSelectOption value="2">
                                                    Docente
                                                </IonSelectOption>
                                                <IonSelectOption value="1">
                                                    Altro
                                                </IonSelectOption>
                                            </IonItem>
                                        </IonSelect>
                                    </div>

                                    {
                                        this.state.role === "1" &&
                                        <div className="eventInputDiv">
                                            <IonInput
                                                className="eventInput"
                                                value={this.state.roleString}
                                                placeholder="Digita il ruolo"
                                                onIonChange={e => {
                                                    this.setState({ roleString: e.detail.value ? e.detail.value : null});
                                                }}
                                            />
                                        </div>
                                    }

                                    {
                                        this.state.role === "2" &&
                                        <>
                                            <div className="eventInputDiv">
                                                <IonInput
                                                    className="eventInput"
                                                    value={this.state.schoolName}
                                                    placeholder="Nome della scuola"
                                                    onIonChange={e => {
                                                        this.setState({ schoolName: e.detail.value ? e.detail.value : null});
                                                    }}
                                                />
                                            </div>
                                            <div className="eventInputDiv">
                                                <IonInput
                                                    className="eventInput"
                                                    value={this.state.city}
                                                    placeholder="Città"
                                                    autocomplete="address-level2"
                                                    onIonChange={e => {
                                                        this.setState({ city: e.detail.value ? e.detail.value : null});
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }

                                    <div className="eventInputDiv">
                                        <IonSelect
                                            className="eventInput"
                                            value={this.state.accountManager}
                                            placeholder="Ho parlato con" /* TO BE LOCALIZED */
                                            onIonChange={e => {
                                                this.setState({ accountManager: e.detail.value});
                                            }}
                                        >
                                            <IonItem>
                                            <IonSelectOption value="1">
                                                    Francesca Restuccia
                                                </IonSelectOption>
                                                <IonSelectOption value="2">
                                                    Lorenzo Daidone
                                                </IonSelectOption>
                                                <IonSelectOption value="5">
                                                    Angelo Sterlini
                                                </IonSelectOption>
                                                <IonSelectOption value="6">
                                                    Robert Galan
                                                </IonSelectOption>
                                                {/* <IonSelectOption value="7">
                                                    Fabio Nicolosi
                                                </IonSelectOption> */}
                                                {/* <IonSelectOption value="8">
                                                    Julie Chicca
                                                </IonSelectOption> */}
                                                <IonSelectOption value="4">
                                                    Andrea Zingoni
                                                </IonSelectOption>
                                                {/* <IonSelectOption value="9">
                                                    María Onieva Montalbán
                                                </IonSelectOption>
                                                <IonSelectOption value="10">
                                                    Enric Albalat Torres
                                                </IonSelectOption> */}
                                                {/* <IonSelectOption value="3">
                                                    Juri Taborri
                                                </IonSelectOption> */}
                                                <IonSelectOption value="-1">
                                                    Non lo so
                                                </IonSelectOption>
                                            </IonItem>
                                        </IonSelect>
                                    </div>



                                    <IonButton
                                        className="eventButtonSubmit"
                                        disabled={
                                            !this.state.email ||
                                            !this.state.surname ||
                                            !this.state.name || 
                                            !this.state.accountManager
                                        }
                                        onClick={() => {
                                            const createLicenseCode = httpsCallable(functions, "createLicenseCode");
                                            this.setState({isLoading: true});
                                            createLicenseCode({
                                                email: this.state.email,
                                                name: this.state.name,
                                                surname: this.state.surname,
                                                role: this.state.role,
                                                roleString: this.state.roleString,
                                                schoolName: this.state.schoolName,
                                                city: this.state.city,
                                                accountManager: this.state.accountManager,
                                                eventId: "d24",
                                            })
                                            .then((res) => {
                                                console.log("[Event] Response:", res.data);
                                                this.setState({
                                                    email: null,
                                                    name: null,
                                                    surname: null,
                                                    role: null,
                                                    roleString: null,
                                                    schoolName: null,
                                                    city: null,
                                                    accountManager: null,
                                                    isLoading: false,
                                                    isSent: true
                                                }, () => {
                                                    setTimeout(() => {
                                                        this.setState({
                                                            isSent: false,
                                                        });
                                                    }, 6000);
                                                });
                                            })
                                            .catch(err => {
                                                console.error("[Event] error calling createLicenseCode function:", err);
                                                this.setState({ isLoading: false });
                                            });
                                        }}
                                    >
                                        Ottieni la licenza {/* TO BE LOCALIZED */}
                                    </IonButton>
                                </>
                            }

                        </div>

                    </IonGrid>
                    
                </IonContent>

                {/* <MenuTabBar /> */}

                <IonLoading
                    isOpen={this.state.isLoading}
                />
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        logout: () => {
            dispatch(authActions.logout());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Event);