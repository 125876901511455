import {
    IonPage,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import "./Screensaver.css";

import reasyLogo from "./reasyLogo.svg";

type Props = {
    history: any,
};

type State = {
    r: number,
    g: number,
    b: number,
    x: number,
    y: number,
    xSpeed: number,
    ySpeed: number,
    widthDVDLogo: number,
    heightDVDLogo: number,
};

const MS_PER_FRAME = 5;

class Screensaver extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            r: 0,
            g: 0,
            b: 0,
            x: 100,
            y: 100,
            xSpeed: 1,
            ySpeed: 1,
            widthDVDLogo: 200,
            heightDVDLogo: 138,
        }
    }

    setRandomColors() {
        this.setState({
            r: this.getRandomNumber(100, 256),
            g: this.getRandomNumber(100, 256),
            b: this.getRandomNumber(100, 256)
        })
    }

    getRandomNumber(min: number, max: number): number {
        return Math.random() * (max - min) + min;
    }

    moveDVDLogo() {
        
        this.setState({
            x: this.state.x + this.state.xSpeed,
            y: this.state.y + this.state.ySpeed
        });

        

        if (this.state.x + this.state.widthDVDLogo >= window.screen.width || this.state.x <= 0) {
            this.setState({xSpeed: -this.state.xSpeed});
            this.setRandomColors();
        }

        if (this.state.y + (this.state.heightDVDLogo) >= window.screen.height || this.state.y <= 0) {
            this.setState({ySpeed: -this.state.ySpeed});
            this.setRandomColors();
        }
    }
      


    componentDidMount(): void {        
        setInterval(() => this.moveDVDLogo(), MS_PER_FRAME);
    }

    render() {
        
        return (
            <IonPage className="screensaverMainDiv">
                <div
                    id="animatedLogo"
                    style={{
                        color: `rgb(${this.state.r}, ${this.state.g}, ${this.state.b})`,
                        transform: `translate(${this.state.x}px, ${this.state.y}px)`
                    }}
                >
                    <img
                        style={{
                            fill: `rgb(${this.state.r}, ${this.state.g}, ${this.state.b})`,
                            color: `rgb(${this.state.r}, ${this.state.g}, ${this.state.b})`,
                            height: this.state.heightDVDLogo,
                            width: this.state.widthDVDLogo,
                        }}
                        src={reasyLogo}
                    />
                </div>
            </IonPage>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screensaver);
