import {
    IonBackButton, IonButton, IonButtons, IonCard, IonCardContent,
    IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonContent, IonDatetime, IonGrid, IonHeader,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonList,
    IonLoading,
    IonModal, IonPage, IonPopover, IonSearchbar, IonSelect, IonSelectOption, IonSpinner, IonTitle, IonToolbar
} from '@ionic/react';
import { chevronForwardOutline, closeOutline, removeOutline, trashOutline } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import apiService from '../../../../apiService';
import autoassignable from '../../../../assets/images/autoassignable.png';
import { auth } from '../../../../firebaseConfig';
import { Plan } from '../../licenses/store/reducers';
import { licensesServices } from '../../licenses/store/services';
import { getPlanName } from '../../utils';
import LicenseDetailsPopover from '../components/LicenseDetailsPopover';
import { Organization, OrganizationDomain, OrganizationInvite, OrganizationLicense, OrganizationMembership } from '../store/reducers';
import { organizationServices } from '../store/services';
import "./OrganizationDetails.css";

type Props = {
    history: any,
};

type State = {
    orgData: Organization | null,
    orgDomains: OrganizationDomain[] | null,
    orgMemberships: OrganizationMembership[] | null
    isLoadingOrgData: boolean,

    nowDate: Date,
    fiveYearsFromNow: Date,
    isDateModalOpen: boolean,

    orgEmailDomain: string | null,

    newUserId: string | null,
    newUserRole: "member" | "admin" | null,


    isLoading: boolean,

    uploadedFile: any,

    productsEditingMode: boolean,
    showRemovalConfirmationPopover: boolean,

    searchMembership: string,

    popoverEvent: any,
    memberToRemove: OrganizationMembership | null,

    orgLicenses: OrganizationLicense[]

    availablePlans: Plan[],
    selectedPlan: Plan | null,
    licensesAmount: number,
    licenseStartDate: number | null,
    isStartDateModalOpen: boolean,
    licenseEndDate: number | null,
    isEndDateModalOpen: boolean,

    isAutoassignable: boolean,
    selectedLicenseView: 'existing' | 'new';
    showLicenseDetails: boolean,
    selectedOrgLicense: OrganizationLicense | null,
    selectedInvitesView: 'new' | 'pending',
    userToInvite: string,
    selectedInvitationLicense: OrganizationLicense | null,
    organizationInvites: OrganizationInvite[] | null

};

class OrganizationDetails extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        const nowDate = new Date();
        const fiveYearsFromNow = new Date(nowDate.getFullYear() + 5, nowDate.getMonth(), nowDate.getDate());

        this.state = {
            orgData: null,
            orgDomains: null,
            orgMemberships: null,
            isLoadingOrgData: false,

            nowDate: nowDate,
            fiveYearsFromNow: fiveYearsFromNow,
            isDateModalOpen: false,

            orgEmailDomain: null,

            newUserId: null,
            newUserRole: null,

            isLoading: false,

            uploadedFile: "",

            productsEditingMode: false,
            showRemovalConfirmationPopover: false,

            searchMembership: "",

            popoverEvent: null,
            memberToRemove: null,

            orgLicenses: [],

            availablePlans: [],
            selectedPlan: null,
            licensesAmount: 0,
            licenseStartDate: null,
            isStartDateModalOpen: false,
            licenseEndDate: null,
            isEndDateModalOpen: false,
            isAutoassignable: false,
            selectedLicenseView: 'existing',
            showLicenseDetails: false,
            selectedOrgLicense: null,
            selectedInvitesView: 'new',
            userToInvite: '',
            selectedInvitationLicense: null,
            organizationInvites: null

        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(user => {
            if (user) {
                const url = new URL(window.location.href);
                console.log("[OrganizationDetails] organization uuid:", url.searchParams.get("id"));
                if (url.searchParams.get("id")) {
                    this.setState({ isLoadingOrgData: true });
                    organizationServices.getOrganization(url.searchParams.get("id")!)
                        .then((orgData) => {
                            this.setState({ orgData: orgData });
                            organizationServices.getOrganizationDomains(orgData.id)
                                .then(domains => {
                                    this.setState({ orgDomains: domains });
                                })
                                .catch(err => {
                                    // console.error("[OrganizationDetails] error calling getOrganizationEmailDomains cloud function:", err);
                                })
                            organizationServices.getOrganizationMemberships(url.searchParams.get("id")!)
                                .then(members => {
                                    console.log("[OrganizationDetails] memberships:", members)
                                    this.setState({ orgMemberships: members })
                                })
                                .catch(e => {
                                    console.error("[OrganizationDetails] error getting org's memberships:", e);
                                })
                            organizationServices.getOrgLicenses(orgData.id)
                                .then(response => {
                                    this.setState({ orgLicenses: response })
                                })
                                .catch(err => {
                                    console.error("[OrganizationDetails] error getting org's licenses:", err);
                                })
                            licensesServices.getAllPlans()
                                .then(response => {
                                    this.setState({ availablePlans: response, selectedPlan: response[0] }) /* ALSO SETS DEFAULT PLAN */
                                })
                            this.setState({ isLoadingOrgData: false });
                        })
                        .catch(err => {
                            console.error("[OrganizationDetails] error calling getOrganization:", err);
                            this.setState({ isLoadingOrgData: false });
                        })
                }
            }
        })
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                defaultHref="/reasy/licenses-manager"
                            />
                        </IonButtons>
                        <IonTitle>
                            Organization {/* TO BE LOCALIZED */}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonGrid className="organizationDetailsMainGrid">

                        {
                            this.state.isLoadingOrgData &&
                            <div>
                                <IonSpinner />
                            </div>
                        }

                        {
                            !this.state.orgData &&
                            !this.state.isLoadingOrgData &&
                            <div>
                                <p>
                                    Nessuna organizzazione selezionata o organizzazione non esistente.
                                </p>
                            </div>
                        }

                        {
                            this.state.orgData &&
                            <>
                                <div className='organizationDataDiv'>
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>
                                                Organization Data: "{this.state.orgData.name}" {/* TO BE LOCALIZED */}
                                            </IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            {
                                                this.state.orgData.created_at &&
                                                <div>
                                                    <p>
                                                        Creato il {(new Date(this.state.orgData.created_at)).toLocaleDateString()}. {/* TO BE LOCALIZED */}
                                                    </p>
                                                </div>
                                            }

                                            {
                                                this.state.orgData.internal_name &&
                                                <div>
                                                    <p>
                                                        Creato il {this.state.orgData.internal_name}. {/* TO BE LOCALIZED */}
                                                    </p>
                                                </div>
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                </div>
                                <div className='organizationsInvitesDiv'>
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>
                                                Organization Invites {/* TO BE LOCALIZED */}
                                            </IonCardTitle>
                                            <IonChip
                                                onClick={() => this.setState({ selectedInvitesView: 'new' })}
                                                className={this.state.selectedInvitesView === 'new' ? 'selectedLicenseView licensesChip' : 'licenseChip'}
                                            >
                                                Send an invite
                                            </IonChip>
                                            <IonChip
                                                onClick={() => {
                                                    if (this.state.orgData && this.state.organizationInvites === null) {
                                                        this.setState({ isLoading: true })
                                                        organizationServices.getOrganizationInvites(this.state.orgData.id).then(response => {
                                                            this.setState({ organizationInvites: response, isLoading: false, selectedInvitesView: 'pending' })
                                                        })
                                                    }
                                                    else {
                                                        this.setState({ selectedInvitesView: 'pending' })
                                                    }
                                                }
                                                }
                                                className={this.state.selectedInvitesView === 'pending' ? 'selectedLicenseView licensesChip' : 'licenseChip'}
                                            >
                                                View pending invites
                                            </IonChip>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            {this.state.selectedInvitesView === 'new' &&
                                                <div className='newInviteDiv'>
                                                    <IonInput
                                                        placeholder='User email'
                                                        debounce={500}
                                                        onIonChange={(e) => {
                                                            if (e.detail.value && e.detail.value?.trim() !== '')
                                                                this.setState({ userToInvite: e.detail.value })
                                                        }}
                                                    />
                                                    <IonSelect
                                                        placeholder='Select a license to assign'
                                                        onIonChange={(e) => {
                                                            this.setState({ selectedInvitationLicense: e.detail.value })
                                                        }}
                                                        interface='action-sheet' value={this.state.selectedInvitationLicense} >
                                                        {this.state.orgLicenses.map(license => {
                                                            return (
                                                                <IonSelectOption
                                                                    value={license}
                                                                >
                                                                    {getPlanName(license.plan_id) + " - " + new Date(license.end_at).toLocaleDateString()}
                                                                </IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                    <p>You can also insert a list of emails, separated by commas ( , )</p>
                                                    <IonButton
                                                        disabled={this.state.userToInvite.trim() === '' || this.state.selectedInvitationLicense === null}
                                                        onClick={() => {
                                                            if (this.state.orgData && this.state.userToInvite && this.state.selectedInvitationLicense) {
                                                                //     this.setState({ isLoading: true })
                                                                //     organizationServices.inviteUserToOrganization(this.state.orgData.id, this.state.userToInvite, this.state.selectedInvitationLicense.id)
                                                                //         .then(() => {
                                                                //             this.setState({ userToInvite: '' })
                                                                //             this.setState({ isLoading: false })
                                                                //         })
                                                                //         .catch(err => {
                                                                //             this.setState({ isLoading: false })
                                                                //         })
                                                                // }

                                                                const idArray = this.state.userToInvite.split(',').map(id => id.trim());
                                                                this.setState({ isLoading: true });
                                                                for (let i = 0; i < idArray.length; i++) {
                                                                    const userEmail = idArray[i];
                                                                    setTimeout(() => {
                                                                        organizationServices.inviteUserToOrganization(this.state.orgData!.id, userEmail, this.state.selectedInvitationLicense!.id)
                                                                            .then(res => {
                                                                                console.log("[OrganizationDetails] new membership created:", res)

                                                                                if (this.state.orgMemberships) {
                                                                                    const newMembershipsArray = [...this.state.orgMemberships, res as OrganizationMembership]
                                                                                    this.setState({ orgMemberships: newMembershipsArray })
                                                                                }
                                                                                else {
                                                                                    this.setState({ orgMemberships: [res as OrganizationMembership] })
                                                                                }

                                                                                this.setState({
                                                                                    isLoading: false,
                                                                                    newUserId: null,
                                                                                    newUserRole: null,
                                                                                });
                                                                            })
                                                                            .catch(err => {
                                                                                console.error("[OrganizationDetails] error creating new membership:", err);
                                                                                this.setState({ isLoading: false });
                                                                            });
                                                                    }, 100 * i);
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        Send invite
                                                    </IonButton>
                                                </div>
                                            }
                                            {this.state.selectedInvitesView === 'pending' &&
                                                <div className='pendingInvitesDiv'>
                                                    <IonList className='pendingInvitesList'>
                                                        {this.state.organizationInvites?.filter(invites => invites.status === 'pending').map(invite => {
                                                            return (
                                                                <IonItem>
                                                                    {invite.email} - {new Date(invite.created_at).toLocaleDateString()}
                                                                </IonItem>
                                                            )
                                                        })}
                                                    </IonList>
                                                </div>
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                </div>
                                <div className='organizationLicensesDiv'>
                                    <IonCard className='organizationLicensesCard'>
                                        <IonCardHeader className='organizationLicensesCardHeader'>
                                            <IonCardTitle>
                                                Licenses
                                            </IonCardTitle>
                                            <IonChip
                                                onClick={() => this.setState({ selectedLicenseView: 'existing' })}
                                                className={this.state.selectedLicenseView === 'existing' ? 'selectedLicenseView licensesChip' : 'licenseChip'}
                                            >
                                                Existing licenses
                                            </IonChip>
                                            <IonChip
                                                onClick={() => this.setState({ selectedLicenseView: 'new' })}
                                                className={this.state.selectedLicenseView === 'new' ? 'selectedLicenseView licensesChip' : 'licenseChip'}
                                            >
                                                Create new
                                            </IonChip>
                                        </IonCardHeader>
                                        <IonCardContent className='organizationLicensesCardContent'>
                                            <div className='orgLicensesDiv'>
                                                {this.state.selectedLicenseView === 'new' &&
                                                    <div className='newLicenseDiv'>
                                                        <div className='newLicensePropertyDiv'>
                                                            <p>
                                                                Plan
                                                            </p>
                                                            <IonSelect value={this.state.selectedPlan} onIonChange={(e) => { this.setState({ selectedPlan: e.detail.value }) }}>
                                                                {this.state.availablePlans.map(plan => {
                                                                    return (
                                                                        <IonSelectOption key={plan.id} value={plan}>{plan.name}</IonSelectOption>
                                                                    )
                                                                })}
                                                            </IonSelect>
                                                        </div>
                                                        <div className='newLicensePropertyDiv'>
                                                            <p>
                                                                # of Licenses
                                                            </p>
                                                            <IonInput
                                                                className='newLicenseAmountInput'
                                                                type='number'
                                                                inputMode='numeric'
                                                                min={0}
                                                                value={this.state.licensesAmount}
                                                                debounce={500}
                                                                onInput={(e) => this.setState({ licensesAmount: Number(e.currentTarget.value) })}
                                                            />
                                                        </div>
                                                        <div className='newLicensePropertyDiv'>
                                                            <p
                                                                onClick={() => {
                                                                    this.setState({ isStartDateModalOpen: true })
                                                                }}
                                                            >
                                                                Starting date
                                                            </p>
                                                            {
                                                                this.state.licenseStartDate ?
                                                                    " " + new Date(this.state.licenseStartDate * 1000).toLocaleDateString() :
                                                                    <IonButton onClick={() => {
                                                                        this.setState({ isStartDateModalOpen: true })
                                                                    }}
                                                                    >
                                                                        Set
                                                                    </IonButton>
                                                            }
                                                        </div>
                                                        <div className='newLicensePropertyDiv' onClick={() => {
                                                            this.setState({ isEndDateModalOpen: true })
                                                        }}>
                                                            <p
                                                                onClick={() => {
                                                                    this.setState({ isEndDateModalOpen: true })
                                                                }}
                                                            >
                                                                Ending date
                                                            </p>
                                                            {
                                                                this.state.licenseEndDate ?
                                                                    " " + new Date(this.state.licenseEndDate * 1000).toLocaleDateString() :
                                                                    <IonButton>
                                                                        Set
                                                                    </IonButton>
                                                            }
                                                        </div>
                                                        <div className='newLicensePropertyDiv'>
                                                            <p>Autoassignable?</p>
                                                            <IonCheckbox
                                                                checked={this.state.isAutoassignable}
                                                                onClick={() => {
                                                                    this.setState({ isAutoassignable: !this.state.isAutoassignable })
                                                                }}
                                                            />
                                                        </div>
                                                        <IonButton
                                                            disabled={
                                                                !this.state.licenseEndDate ||
                                                                !this.state.licenseStartDate ||
                                                                !this.state.selectedPlan ||
                                                                this.state.licensesAmount <= 0 ||
                                                                !this.state.orgData ||
                                                                !this.state.orgLicenses
                                                            }
                                                            onClick={() => {
                                                                if (this.state.licenseEndDate! < this.state.licenseStartDate!) {
                                                                    alert("Starting date is greater than ending date")
                                                                    return;
                                                                }
                                                                if (this.state.orgData && this.state.licenseEndDate) {
                                                                    this.setState({ isLoading: true });
                                                                    organizationServices.createOrgLicense(
                                                                        this.state.orgData.id,
                                                                        this.state.selectedPlan!.id,
                                                                        this.state.licensesAmount,
                                                                        this.state.licenseStartDate!,
                                                                        this.state.licenseEndDate,
                                                                        this.state.isAutoassignable,
                                                                        this.state.orgLicenses
                                                                    )
                                                                        .then(response => {
                                                                            let tempLicenses = this.state.orgLicenses
                                                                            tempLicenses.push(response.newLicense)
                                                                            response.updatedLicenses.forEach((license: any) => {
                                                                                let correspondantLicense = tempLicenses.filter(lic => lic.id === license.id)[0]
                                                                                tempLicenses.splice(tempLicenses.indexOf(correspondantLicense), 1)
                                                                                tempLicenses.push(license)
                                                                            });
                                                                            this.setState({ isLoading: false, orgLicenses: tempLicenses })
                                                                        })
                                                                        .catch(err => {
                                                                            this.setState({ isLoading: false })
                                                                        })
                                                                }
                                                            }}
                                                        >
                                                            Add license {/* TO BE LOCALIZED */}
                                                        </IonButton>
                                                        <IonModal
                                                            isOpen={this.state.isStartDateModalOpen || this.state.isEndDateModalOpen}
                                                            onDidDismiss={() => {
                                                                this.setState({ isStartDateModalOpen: false, isEndDateModalOpen: false });
                                                            }}
                                                        >
                                                            <IonContent>
                                                                <p style={{ textAlign: 'center' }}>{this.state.isStartDateModalOpen ? "License start date" : "License end date"}</p>
                                                                <IonDatetime
                                                                    max={new Date(new Date().setFullYear(new Date().getFullYear() + 5)).toISOString()}
                                                                    onIonChange={e => {
                                                                        if (this.state.isStartDateModalOpen) {
                                                                            this.setState({ licenseStartDate: e.detail.value ? Math.floor((new Date(e.detail.value)).getTime() / 1000) : null });
                                                                        }
                                                                        else if (this.state.isEndDateModalOpen) {
                                                                            this.setState({ licenseEndDate: e.detail.value ? Math.floor((new Date(e.detail.value)).getTime() / 1000) : null });
                                                                        }

                                                                    }}
                                                                />

                                                                <IonButton
                                                                    onClick={() => {
                                                                        this.setState({ isStartDateModalOpen: false, isEndDateModalOpen: false });
                                                                    }}
                                                                >
                                                                    Done {/* TO BE LOCALIZED */}
                                                                </IonButton>
                                                            </IonContent>
                                                        </IonModal>
                                                    </div>
                                                }
                                                {this.state.selectedLicenseView === 'existing' &&
                                                    <div className='availableLicensesDiv'>
                                                        {this.state.orgLicenses.map(license => {
                                                            return (
                                                                <IonCard
                                                                    onClick={() => {
                                                                        this.setState({ showLicenseDetails: true, selectedOrgLicense: license })
                                                                    }}
                                                                    className='availableLicenseCard'
                                                                >
                                                                    <IonCardTitle>{getPlanName(license.plan_id)}</IonCardTitle>
                                                                    <IonCardSubtitle>
                                                                        Expires: {new Date(license.end_at).toLocaleDateString()}
                                                                    </IonCardSubtitle>
                                                                    <IonImg hidden={!license.autoassignable} className='autoassignableIcon' src={autoassignable} />
                                                                    <IonIcon icon={chevronForwardOutline} className='detailsArrow' />
                                                                </IonCard>
                                                            )
                                                        })}


                                                        <LicenseDetailsPopover
                                                            history={this.props.history}
                                                            isOpen={this.state.showLicenseDetails}
                                                            license={this.state.selectedOrgLicense}
                                                            existingLicenses={this.state.orgLicenses}
                                                            orgData={this.state.orgData}
                                                            orgMemberships={this.state.orgMemberships}
                                                            onLicenseUpdated={(updatedLicenses => {

                                                                let tempLicenses = this.state.orgLicenses
                                                                updatedLicenses.forEach((license: any) => {
                                                                    let correspondantLicense = tempLicenses.filter(lic => lic.id === license.id)[0]
                                                                    tempLicenses.splice(tempLicenses.indexOf(correspondantLicense), 1)
                                                                    tempLicenses.push(license)
                                                                });
                                                            })}
                                                            onLicenseDeletion={(deletedLicense) => {
                                                                let tempArray = this.state.orgLicenses.filter(license => license.id !== deletedLicense.id)
                                                                this.setState({ orgLicenses: tempArray })
                                                            }}
                                                            onDismiss={() => this.setState({ showLicenseDetails: false })}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </IonCardContent>
                                    </IonCard>
                                </div>
                                <div className='organizationDomainsDiv'>
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>
                                                Email domains {/* TO BE LOCALIZED */}
                                            </IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>

                                            {
                                                this.state.orgDomains &&
                                                <div>
                                                    {
                                                        this.state.orgDomains.length === 0 &&
                                                        <p>
                                                            Nessun dominio email associato all'organizzazione. {/* TO BE LOCALIZED */}
                                                        </p>
                                                    }

                                                    <IonList>
                                                        {
                                                            this.state.orgDomains &&
                                                            this.state.orgDomains.map(orgDomain => {
                                                                return (
                                                                    <IonItem className='organizationDomainItem' key={orgDomain.id}>
                                                                        <IonIcon
                                                                            className='removeDomain'
                                                                            icon={trashOutline}
                                                                            onClick={() => {
                                                                                if (this.state.orgData && this.state.orgDomains) {
                                                                                    this.setState({ isLoading: true });
                                                                                    organizationServices.removeDomainFromOrg(this.state.orgData.id, orgDomain.id)
                                                                                        .then(resposne => {
                                                                                            this.setState({
                                                                                                orgDomains: this.state.orgDomains!.filter(domain => domain.id !== orgDomain.id),
                                                                                                isLoading: false
                                                                                            })
                                                                                        })
                                                                                        .catch(err => {
                                                                                            this.setState({ isLoading: false })
                                                                                        })
                                                                                }
                                                                            }}
                                                                        />
                                                                        {orgDomain.domain_name}
                                                                    </IonItem>
                                                                )
                                                            })
                                                        }
                                                    </IonList>
                                                </div>
                                            }


                                            <div>
                                                <IonInput
                                                    placeholder="Organization email domain, without @" /* TO BE LOCALIZED */
                                                    value={this.state.orgEmailDomain}
                                                    onIonChange={(e) => {
                                                        this.setState({ orgEmailDomain: e.detail.value ? e.detail.value : null });
                                                    }}
                                                />
                                                <div className="organizationDetailsEmailDomainsAssignButtonDiv">
                                                    <IonButton
                                                        disabled={
                                                            !this.state.orgEmailDomain
                                                        }
                                                        onClick={() => {
                                                            if (this.state.orgData && this.state.orgEmailDomain) {
                                                                this.setState({ isLoading: true });
                                                                organizationServices.addDomainToOrg(this.state.orgData.id, this.state.orgEmailDomain.trim())
                                                                    .then(res => {
                                                                        if (this.state.orgDomains) {
                                                                            const newDomainssArray = [...this.state.orgDomains, res as OrganizationDomain]
                                                                            this.setState({ orgDomains: newDomainssArray })
                                                                        }
                                                                        else {
                                                                            this.setState({ orgDomains: [res as OrganizationDomain] })
                                                                        }
                                                                        this.setState({
                                                                            isLoading: false,
                                                                            orgEmailDomain: null,
                                                                        });
                                                                    })
                                                                    .catch(err => {
                                                                        console.error("[OrganizationDetails] error calling assignEmailDomainToOrganization cloud function:", err);
                                                                        this.setState({ isLoading: false });
                                                                    });
                                                            }
                                                        }}
                                                    >
                                                        Assegna dominio all'organizzazione{/* TO BE LOCALIZED */}
                                                    </IonButton>
                                                </div>
                                            </div>
                                        </IonCardContent>
                                    </IonCard>
                                </div>
                                <div className='organizationMembershipsDiv'>
                                    <IonCard className='organizationMembershipsCard'>
                                        <IonCardHeader>
                                            <IonCardTitle>
                                                Memberships
                                            </IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent className='organizationMembershipsCardContent'>
                                            <IonSearchbar
                                                onIonChange={(e) => {
                                                    if (e.detail.value !== undefined)
                                                        this.setState({
                                                            searchMembership: e.detail.value
                                                        })
                                                }}
                                            />
                                            {
                                                this.state.orgMemberships &&

                                                <IonList className='orgMembersList'>
                                                    {
                                                        this.state.orgMemberships.length === 0 &&
                                                        <p>
                                                            Nessun membro associato all'organizzazione. {/* TO BE LOCALIZED */}
                                                        </p>
                                                    }
                                                    <p>{this.state.orgMemberships
                                                        .filter(member => member.user_id.includes(this.state.searchMembership)).length}</p>
                                                    {
                                                        this.state.orgMemberships &&
                                                        this.state.orgMemberships
                                                            .filter(member => member.user_id.includes(this.state.searchMembership))
                                                            .sort((a, b) => Number(a.joined_at) - Number(b.joined_at))
                                                            .map(orgMembership => {
                                                                return (
                                                                    <div
                                                                        className='orgMembershipDiv'
                                                                        key={orgMembership.id}
                                                                    >
                                                                        <IonIcon id='memberRemove' icon={closeOutline} className='orgMembershipRemoveIcon'
                                                                            onClick={(e) => {
                                                                                this.setState({ memberToRemove: orgMembership, popoverEvent: e, showRemovalConfirmationPopover: true })
                                                                            }} />
                                                                        <div>
                                                                            <p
                                                                                className='orgMemberUuid'
                                                                                onClick={() => {
                                                                                    this.props.history.push(`/reasy/user?id=${orgMembership.user_id}`)
                                                                                }}
                                                                            >
                                                                                {orgMembership.user_id}
                                                                            </p>
                                                                        </div>
                                                                        <IonChip
                                                                            onClick={() => {
                                                                                let role: 'member' | 'admin' = orgMembership.role === 'admin' ? 'member' : 'admin'
                                                                                this.setState({ isLoading: true })
                                                                                organizationServices.updateMemberRole(orgMembership.org_id, orgMembership.id, role)
                                                                                    .then(updatedMember => {
                                                                                        if (this.state.orgMemberships) {
                                                                                            const updatedArray = this.state.orgMemberships?.map(membership => {
                                                                                                if (membership.user_id === updatedMember.user_id)
                                                                                                    return updatedMember
                                                                                                else
                                                                                                    return membership
                                                                                            })
                                                                                            this.setState({ orgMemberships: updatedArray, isLoading: false })
                                                                                        }
                                                                                    })
                                                                                    .catch(err => {
                                                                                        this.setState({ isLoading: false })
                                                                                    })
                                                                            }}
                                                                            className={orgMembership.role === 'admin' ? 'adminRoleChip userRoleChip' : 'memberRoleChip userRoleChip'}
                                                                        >
                                                                            {orgMembership.role}
                                                                        </IonChip>
                                                                    </div>
                                                                )
                                                            })
                                                    }
                                                </IonList>
                                            }


                                            <div>
                                                <IonInput
                                                    placeholder="New user id" /* TO BE LOCALIZED */
                                                    value={this.state.newUserId}
                                                    onIonChange={(e) => {
                                                        this.setState({ newUserId: e.detail.value ? e.detail.value : null });
                                                    }}
                                                />
                                                <IonSelect
                                                    placeholder="Member role"
                                                    value={this.state.newUserRole}
                                                    onIonChange={e => {
                                                        if (e.detail.value === "admin") {
                                                            this.setState({ newUserRole: "admin" })
                                                        }
                                                        else {
                                                            this.setState({ newUserRole: "member" })
                                                        }
                                                    }}
                                                >
                                                    <IonSelectOption value={"member"}>
                                                        Member
                                                    </IonSelectOption>
                                                    <IonSelectOption value={"admin"}>
                                                        Admin
                                                    </IonSelectOption>
                                                </IonSelect>
                                                <div className="organizationDetailsEmailDomainsAssignButtonDiv">
                                                    <p>You can also insert a list of UIDs, separated by commas ( , )</p>
                                                    <IonButton
                                                        disabled={
                                                            !this.state.newUserId ||
                                                            !this.state.newUserRole
                                                        }
                                                        onClick={() => {
                                                            if (this.state.orgData && this.state.newUserId) {
                                                                const idArray = this.state.newUserId.split(',').map(id => id.trim());
                                                                this.setState({ isLoading: true });
                                                                for (let i = 0; i < idArray.length; i++) {
                                                                    const userID = idArray[i];
                                                                    setTimeout(() => {
                                                                        apiService.post(`/organizations/${this.state.orgData!.id}/memberships`,
                                                                            {
                                                                                user_id: userID,
                                                                                role: this.state.newUserRole,
                                                                            })
                                                                            .then(res => {
                                                                                console.log("[OrganizationDetails] new membership created:", res)

                                                                                if (this.state.orgMemberships) {
                                                                                    const newMembershipsArray = [...this.state.orgMemberships, res as OrganizationMembership]
                                                                                    this.setState({ orgMemberships: newMembershipsArray })
                                                                                }
                                                                                else {
                                                                                    this.setState({ orgMemberships: [res as OrganizationMembership] })
                                                                                }

                                                                                this.setState({
                                                                                    isLoading: false,
                                                                                    newUserId: null,
                                                                                    newUserRole: null,
                                                                                });
                                                                            })
                                                                            .catch(err => {
                                                                                console.error("[OrganizationDetails] error creating new membership:", err);
                                                                                this.setState({ isLoading: false });
                                                                            });
                                                                    }, 100 * i);
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        Create membership for the user {/* TO BE LOCALIZED */}
                                                    </IonButton>
                                                    {/*       <IonButton onClick={() => {
                                                        console.log(this.state.orgMemberships);

                                                        for (let i = 0; i < this.state.orgMemberships!.length; i++) {
                                                            setTimeout(() => {
                                                                organizationServices.removeMember(this.state.orgData!.id, this.state.orgMemberships![i].id)
                                                            }, 100 * i);
                                                        }

                                                    }}
                                                        color='danger'
                                                    >
                                                        Remove all
                                                    </IonButton> */}
                                                </div>
                                            </div>
                                        </IonCardContent>
                                    </IonCard>
                                </div>
                                <IonPopover
                                    onDidDismiss={() => { this.setState({ showRemovalConfirmationPopover: false }) }}
                                    isOpen={this.state.showRemovalConfirmationPopover}
                                    event={this.state.popoverEvent}
                                >
                                    <div className='removeMemberPopoverDiv'>
                                        <p>Are you sure</p>
                                        <IonButton
                                            onClick={() => {
                                                if (this.state.orgData && this.state.memberToRemove && this.state.orgMemberships) {
                                                    this.setState({ isLoading: true })
                                                    organizationServices.removeMember(this.state.orgData.id, this.state.memberToRemove.id)
                                                        .then(response => {
                                                            this.setState({
                                                                isLoading: false,
                                                                orgMemberships: this.state.orgMemberships!.filter(orgMembership => orgMembership.id !== this.state.memberToRemove!.id),
                                                                showRemovalConfirmationPopover: false
                                                            })
                                                        })
                                                }
                                            }}
                                            color='danger'
                                        >
                                            Remove
                                        </IonButton>

                                    </div>
                                </IonPopover>

                            </>
                        }


                    </IonGrid>
                </IonContent>
                <IonLoading
                    isOpen={this.state.isLoading}
                />
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetails);