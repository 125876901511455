import React from 'react';
import {
    IonTabBar, IonTabButton, IonIcon, IonLabel,
} from '@ionic/react';
import { fileTrayFullOutline, notificationsOutline, person, storefront } from 'ionicons/icons'
import { connect } from 'react-redux';

type Props = {
    history?: any,
}

class MenuTabBar extends React.Component<Props> {

    render() {
        return (
            <IonTabBar className='bottomTabBar' slot="bottom">

                <IonTabButton tab="reasy" href="/reasy">
                    <IonIcon icon={fileTrayFullOutline} />
                    <IonLabel>Reasy</IonLabel>
                </IonTabButton>

                <IonTabButton tab="profile" href="/profile">
                    <IonIcon icon={person} />
                    <IonLabel>Profile</IonLabel> {/* TO LOCALIZE THIS */}
                </IonTabButton>

                <IonTabButton tab="notification" href="/notification">
                    <IonIcon icon={notificationsOutline} />
                    <IonLabel>Notifications</IonLabel> {/* TO LOCALIZE THIS */}
                </IonTabButton>

                {/* <IonTabButton tab="semana" href="/semana">
                    <IonIcon icon={storefront} />
                    <IonLabel>Madrid</IonLabel>
                </IonTabButton> */}

                {/* <IonTabButton tab="salo" href="/salo">
                    <IonIcon icon={storefront} />
                    <IonLabel>Barcelona</IonLabel>
                </IonTabButton> */}

                {/* <IonTabButton tab="didacta" href="/didacta">
                    <IonIcon icon={storefront} />
                    <IonLabel>Didacta</IonLabel>
                </IonTabButton> */}

            </IonTabBar>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MenuTabBar);
