import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
/* import logger from 'redux-logger'; // NOT FOR PRODUCTION */

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Authentication from '../modules/authentication/store/reducers';

/* const middlewares = [thunk, logger]; // NOT FOR PRODUCTION */
const middlewares = [thunk];

const rootReducer = combineReducers({
    auth: Authentication,
});

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(persistedReducer, applyMiddleware(...middlewares));
let persistor = persistStore(store);

export { store, persistor }