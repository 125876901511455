import apiService from "../../../../apiService"
import { UserLicense } from "../../licenses/store/reducers"
import { Organization, OrganizationDomain, OrganizationInvite, OrganizationLicense, OrganizationMembership } from "./reducers"

export const organizationServices = {
    getOrganization,

    getOrganizationDomains,
    addDomainToOrg,
    removeDomainFromOrg,

    getOrganizationMemberships,
    getMemberLicenses,
    updateMemberRole,
    removeMember,

    getOrgLicenses,
    createOrgLicense,
    updateOrgLicense,
    deleteOrgLicense,
    assignLicenseToMember,

    getOrganizationInvites,
    inviteUserToOrganization,
}


function getOrganization(org_id: string) {
    return new Promise<Organization>((resolve, reject) => {
        apiService.get(`/organizations/${org_id}`)
            .then((response: any) => {
                console.log("[getOrganization] got org:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[getOrganization] ERROR getting org:", error)
                reject(error)
            })
    })
}
/* 
#   ----------------ORGANIZATION DOMAINS----------------
*/
function getOrganizationDomains(org_id: string) {
    return new Promise<OrganizationDomain[]>((resolve, reject) => {
        apiService.get(`/organizations/${org_id}/domains`)
            .then((response: any) => {
                console.log("[getOrganizationDomains] got org domains:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[getOrganizationDomains] ERROR getting org domains:", error)
                reject(error)
            })
    })
}
function addDomainToOrg(org_id: string, domain_name: string) {
    return new Promise<OrganizationDomain>((resolve, reject) => {
        const data = {
            domain_name: domain_name
        }
        apiService.post(`/organizations/${org_id}/domains`, data)
            .then((response: any) => {
                console.log("[addDomainToOrg] added org domain:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[addDomainToOrg] ERROR adding org domain:", error)
                reject(error)
            })
    })
}
function removeDomainFromOrg(org_id: string, domain_id: string) {
    return new Promise<any>((resolve, reject) => {
        apiService.delete(`/organizations/${org_id}/domains/${domain_id}`)
            .then((response: any) => {
                console.log("[removeDomainFromOrg] deleted org domain:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[removeDomainFromOrg] ERROR deleting org domain:", error)
                reject(error)
            })
    })
}
/* 
#   ----------------END ORGANIZATION DOMAINS----------------
*/



/* 
#   ----------------ORGANIZATION MEMBERSHIPS----------------
*/
function getOrganizationMemberships(org_id: string) {
    return new Promise<OrganizationMembership[]>((resolve, reject) => {
        apiService.get(`/organizations/${org_id}/memberships`)
            .then((response: any) => {
                console.log("[getOrganizationMemberships] got org memberships:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[getOrganizationMemberships] ERROR getting org memberships:", error)
                reject(error)
            })
    })
}
function getMemberLicenses(org_id: string, user_id: string) {
    return new Promise<UserLicense[]>((resolve, reject) => {
        apiService.get(`/organizations/${org_id}/members/${user_id}/licenses`)
            .then((response: any) => {
                console.log("[getMemberLicenses] got member licenses:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[getMemberLicenses] ERROR getting member licenses:", error)
                reject(error)
            })
    })
}


function updateMemberRole(org_id: string, membership_id: string, role: 'member' | 'admin') {
    return new Promise<OrganizationMembership>((resolve, reject) => {
        const body = {
            "role": role
        }
        apiService.patch(`/organizations/${org_id}/memberships/${membership_id}`, body)
            .then((response: any) => {
                console.log("[updateMemberRole] updated member role:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[updateMemberRole] ERROR updating member role:", error)
                reject(error)
            })
    })
}

function removeMember(org_id: string, membership_id: string) {
    return new Promise<any>((resolve, reject) => {
        apiService.delete(`/organizations/${org_id}/memberships/${membership_id}`)
            .then((response: any) => {
                console.log("[removeMember] removed member:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[removeMember] ERROR removing member role:", error)
                reject(error)
            })
    })
}

/* 
#   ----------------END ORGANIZATION MEMBERSHIPS----------------
*/



/* 
#   ----------------ORGANIZATION LICENSES----------------
*/
function getOrgLicenses(org_id: string) {
    return new Promise<OrganizationLicense[]>((resolve, reject) => {
        apiService.get(`/organizations/${org_id}/licenses/`)
            .then((response: any) => {
                console.log("[getOrgLicenses] got licenses:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[getOrgLicenses] ERROR getting org licenses:", error)
                reject(error)
            })
    })
}
function createOrgLicense(org_id: string, plan_id: string, total_licenses: number, start_at: number, end_at: number, autoassignable: boolean, existingLicenses: OrganizationLicense[]) {
    return new Promise<any>((resolve, reject) => {
        const data = {
            "plan_id": plan_id,
            "total_licenses": total_licenses,
            "start_at": start_at,
            "end_at": end_at,
            "autoassignable": autoassignable ? 1 : 0
        }
        let updatedLicenses: any[] = []
        apiService.post(`/organizations/${org_id}/licenses/`, data)
            .then(async (response: any) => {
                console.log("[createOrgLicense] created license:", response)
                if (autoassignable) {
                    /* UPDATE REMOVE "AUTOASSIGNABLE" FROM THE OTHER LICENSE IF THERE'S ONE */
                    if (existingLicenses.length > 0)
                        existingLicenses.forEach(async license => {
                            if (license.autoassignable) {
                                updateOrgLicense(org_id, license.id, license.total_licenses, new Date(license.start_at).getTime() / 1000, new Date(license.end_at).getTime() / 1000, false).then(updatedLicense => {
                                    updatedLicenses.push(updatedLicense)
                                    resolve({
                                        newLicense: response,
                                        updatedLicenses
                                    })
                                })
                            }
                        })
                    else {
                        resolve({
                            newLicense: response,
                            updatedLicenses
                        })
                    }
                }
                else {
                    console.log("[createOrgLicense] created license:", response)
                    resolve({
                        newLicense: response,
                        updatedLicenses
                    })
                }
            })
            .catch(error => {
                console.error("[createOrgLicense] ERROR creating org license:", error)
                reject(error)
            })
    })
}

function updateOrgLicense(org_id: string, org_license_id: string, total_licenses: number, start_at: number, end_at: number, autoassignable: boolean, existingLicenses?: OrganizationLicense[]) {
    return new Promise<any>((resolve, reject) => {
        const data = {
            "total_licenses": total_licenses,
            "start_at": start_at,
            "end_at": end_at,
            "autoassignable": autoassignable ? 1 : 0
        }
        let updatedLicenses: any[] = []
        apiService.patch(`/organizations/${org_id}/licenses/${org_license_id}`, data)
            .then(async (response: any) => {
                updatedLicenses.push(response)
                if (autoassignable && existingLicenses) {
                    for (let i = 0; i < existingLicenses.length; i++) {
                        const license = existingLicenses[i];
                        if (license.autoassignable && license.id !== org_license_id)
                            await updateOrgLicense(org_id, license.id, license.total_licenses, new Date(license.start_at).getTime() / 1000, new Date(license.end_at).getTime() / 1000, false).then(updatedLicense => {
                                updatedLicenses.push(updatedLicense)
                                console.log("[updateOrgLicense] updated org license:", response)
                            })

                    }
                    console.log("I AM GOING TO RESOLVE 1: ", JSON.stringify(updatedLicenses));
                    resolve({
                        updatedLicenses: updatedLicenses,/* !!!! THIS ARRAY DOES NOT INCLUDE THE ORIGINAL UPDATED LICENSE, SINCE WE JUST NEED THE ONES THAT HAS BEEN UPDATED AFTER */
                    })
                }
                else {
                    console.log("I AM GOING TO RESOLVE 2: ", JSON.stringify(updatedLicenses));
                    console.log("[updateOrgLicense] updated org license:", response)
                    resolve(response)
                }
            })
            .catch(error => {
                console.error("[updateOrgLicense] ERROR updating org license:", error)
                reject(error)
            })
    })
}
function deleteOrgLicense(org_id: string, org_license_id: string) {
    return new Promise<any>((resolve, reject) => {
        apiService.delete(`/organizations/${org_id}/licenses/${org_license_id}`)
            .then((response: any) => {
                console.log("[deleteOrgLicense] deleted org license:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[deleteOrgLicense] ERROR deleting org license:", error)
                reject(error)
            })
    })
}
function assignLicenseToMember(org_id: string, member_id: string, org_license_id: string) {
    return new Promise<any>((resolve, reject) => {
        const data = {
            org_license_id: org_license_id
        }
        apiService.post(`/organizations/${org_id}/members/${member_id}/licenses/`, data)
            .then((response: any) => {
                console.log("[assignLicenseToMember] adding org license to user:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[assignLicenseToMember] ERROR adding org license:", error)
                reject(error)
            })
    })
}

/*
#   ----------------END ORGANIZATION LICENSES----------------
*/
/*
#   ----------------ORGANIZATION INVITES----------------
*/
function getOrganizationInvites(org_id: string) {
    return new Promise<OrganizationInvite[]>((resolve, reject) => {
        apiService.get(`/organizations/${org_id}/invitations`)
            .then((response: any) => {
                console.log("[getOrganizationInvites] got invitations:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[getOrganizationInvites] ERROR getting invitations:", error)
                reject(error)
            })
    })
}
function inviteUserToOrganization(org_id: string, user_email: string, org_license_id: string) {
    return new Promise<any>((resolve, reject) => {
        const data = {
            email: user_email,
            org_license_id: org_license_id
        }
        apiService.post(`/organizations/${org_id}/invitations`, data)
            .then((response: any) => {
                console.log("[inviteUserToOrganization] sent invitation:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[inviteUserToOrganization] ERROR sending invitation:", error)
                reject(error)
            })
    })
}
/*
#   ----------------END ORGANIZATION INVITES----------------
*/