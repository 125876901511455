import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { store } from './common/store';
import AuthenticationLogin from './modules/authentication/pages/AuthenticationLogin';
import ReasyHome from './modules/reasy/home/ReasyHome';
import Profile from './modules/profile/pages/Profile';
import OrganizationsList from './modules/reasy/organizations/pages/OrganizationsList';
import OrganizationCreation from './modules/reasy/organizations/pages/OrganizationCreation';
import OrganizationDetails from './modules/reasy/organizations/pages/OrganizationDetails';
import ReasyUserDetails from './modules/reasy/users/pages/ReasyUserDetails';
import Semana from './modules/events/pages/Semana';
import Salo from './modules/events/pages/Salo';
import Didacta from './modules/events/pages/Didacta';
import ReasyUserCreation from './modules/reasy/users/pages/ReasyUserCreation';
import ReasyPendingUser from './modules/reasy/users/pages/ReasyPendingUser';
import ReasyNotificationSender from './modules/notifications/pages/ReasyNotificationSender';
import Screensaver from './modules/screensaver/pages/Screensaver';


function PrivateRoute({ ...rest }: any) {
    let isAuthenticated = store.getState().auth.userData ? true : false;
    //console.log('[Router] isAuthenticated:', isAuthenticated);
    return (
        <Route
            {...rest}
            render={isAuthenticated ? rest['render'] : () => <Redirect to="/authentication/login" />}
        />
    );
}

function Router() {
    return (

        <IonReactRouter>
            <IonRouterOutlet>
                <Switch>

                    <Route
                        exact path="/authentication/login"
                        render={(routeProps: any) => <AuthenticationLogin  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/reasy"
                        render={(routeProps: any) => <ReasyHome  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/reasy/user"
                        render={(routeProps: any) => <ReasyUserDetails  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/reasy/new-user"
                        render={(routeProps: any) => <ReasyUserCreation  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/reasy/pending-user"
                        render={(routeProps: any) => <ReasyPendingUser  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/reasy/licenses-manager"
                        render={(routeProps: any) => <OrganizationsList  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/reasy/licenses-manager/new-organization"
                        render={(routeProps: any) => <OrganizationCreation  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/reasy/licenses-manager/organization"
                        render={(routeProps: any) => <OrganizationDetails  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/profile"
                        render={(routeProps: any) => <Profile  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/notification"
                        render={(routeProps: any) => <ReasyNotificationSender  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/semana"
                        render={(routeProps: any) => <Semana  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/salo"
                        render={(routeProps: any) => <Salo  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/didacta"
                        render={(routeProps: any) => <Didacta  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/screensaver"
                        render={(routeProps: any) => <Screensaver  {...routeProps} />}
                    />

                    <Redirect to="/reasy" />
                </Switch>
            </IonRouterOutlet>
        </IonReactRouter>
    );
}

export default Router;