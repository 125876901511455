import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonFab,
    IonFabButton,
    IonGrid,
    IonIcon,
    IonLabel,
    IonLoading,
    IonModal,
    IonPopover,
    IonRow
} from '@ionic/react';
import { arrowDownSharp, arrowUpSharp, closeOutline } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { Plan, UserLicense } from '../../licenses/store/reducers';
import "./HandleMailerlitePopover.css";
import "../pages/ReasyUserDetails.css";
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../firebaseConfig';
import { UserDbInfo } from '../store/reducers';

type Props = {
    history: any,
    userDbInfo: UserDbInfo | null,
    isOpen: boolean,

    userMailerliteGroups: any[],
    tempUserMailerliteGroups: any[],
    initialTempUserMailerliteGroups: any[]
    allMailerliteGroups: any[]
    mailerLiteSubscriberId: string,
    assignDate: number | null

    onDismiss: () => void,
    updateAssignDate: (assignDate: any) => void,
    groupClicked: (group: any) => void,
};

type State = {
    isLoading: boolean,
    fiveYearsFromNow: Date,

    mailerliteGroupsChanges: {
        added: any[],
        deleted: any[]
    },
    mailerliteImportantGroups: string[],

    showSecondaryGroups: boolean,

    isAssignDateModalOpen: boolean,

    showConfirmationPopover: boolean,

};

class HandleMailerlitePopover extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        const nowDate = new Date();
        const fiveYearsFromNow = new Date(nowDate.getFullYear() + 5, nowDate.getMonth(), nowDate.getDate());

        this.state = {
            isLoading: false,
            fiveYearsFromNow: fiveYearsFromNow,

            mailerliteGroupsChanges: {
                added: [],
                deleted: []
            },
            mailerliteImportantGroups: ["101107758551533469", "101107779014494163", "95481114482706184", "103811791718450364", "101272714622273189", "95481114495289101", "103109141593065084", "103817082758497879", "101272740947822470"],

            showSecondaryGroups: false,

            isAssignDateModalOpen: false,

            showConfirmationPopover: false

        }
    }

    componentDidMount(): void {
    }

    findArrayChanges = () => {
        const initialGroups = this.props.initialTempUserMailerliteGroups;
        const currentGroups = this.props.tempUserMailerliteGroups;

        const addedGroups = currentGroups.filter(group => !initialGroups.some(initialGroup => initialGroup.id === group.id));
        const deletedGroups = initialGroups.filter(initialGroup => !currentGroups.some(group => group.id === initialGroup.id));

        console.log("Added Groups:", addedGroups);
        console.log("Deleted Groups:", deletedGroups);
        let changes = {
            added: addedGroups,
            deleted: deletedGroups
        }
        this.setState({ mailerliteGroupsChanges: changes })
        return { addedGroups, deletedGroups };
    }

    render() {
        return (
            <>
                <IonPopover
                    isOpen={this.props.isOpen}
                    onDidDismiss={(e) => {
                        this.props.onDismiss()
                    }}
                    className='mailerlitePopover'
                >
                    <IonFab horizontal='end' vertical='top'>
                        <IonFabButton onClick={() => {
                            this.props.onDismiss()
                        }}>
                            <IonIcon
                                icon={closeOutline}
                            />
                        </IonFabButton>
                    </IonFab>
                    <div className='mailerlitePopoverDiv'>
                        <div className='mailerlitePopoverContentDiv'>
                            <IonCard >
                                <IonCardHeader>
                                    <IonCardTitle>
                                        Mailerlite groups{/* TO BE LOCALIZED */}
                                    </IonCardTitle>
                                    <IonCardSubtitle hidden={this.props.mailerLiteSubscriberId !== ''} style={{ color: 'red' }}>
                                        User is not subscribed to mailerlite, clicking on "Save groups" will create and assign user to selected groups
                                    </IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardSubtitle className='mailGroupsSubTitle'>Primary groups</IonCardSubtitle>
                                <IonCardContent>
                                    <div className='mailGroupsDiv'>
                                        {
                                            this.props.allMailerliteGroups.filter(group => this.state.mailerliteImportantGroups.includes(group.id)).map((group, i) => {
                                                return (
                                                    <div className='mailImportantGroups' key={i}>
                                                        <IonCard
                                                            key={i}
                                                            onClick={() => {
                                                                this.props.groupClicked(group)
                                                            }}
                                                            className={this.props.tempUserMailerliteGroups.filter(userGroup => group.id === userGroup.id).length > 0 ? 'mailGroupCard mailGroupChecked' : 'mailGroupCard'}
                                                        >
                                                            <IonLabel>{group.name}</IonLabel>
                                                        </IonCard>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <IonCardSubtitle className='mailGroupsSubTitle'>Subgroups</IonCardSubtitle>
                                    <IonButton
                                        onClick={() => { this.setState({ showSecondaryGroups: !this.state.showSecondaryGroups }) }}>
                                        {this.state.showSecondaryGroups ? 'Hide' : 'Show'}
                                        <IonIcon slot='end' icon={this.state.showSecondaryGroups ? arrowUpSharp : arrowDownSharp} />
                                    </IonButton>
                                    <div className='mailGroupsDiv' hidden={!this.state.showSecondaryGroups}>
                                        {
                                            this.props.allMailerliteGroups.filter(group => !this.state.mailerliteImportantGroups.includes(group.id)).map((group, i) => {
                                                return (
                                                    <div className='mailDefaultGroups' key={i}>
                                                        <IonCard
                                                            key={i}
                                                            onClick={() => {
                                                                this.props.groupClicked(group)
                                                            }}
                                                            className={this.props.tempUserMailerliteGroups.filter(userGroup => group.id === userGroup.id).length > 0 ? 'mailGroupCard mailGroupChecked' : 'mailGroupCard'}
                                                        >
                                                            <IonLabel>{group.name}</IonLabel>
                                                        </IonCard>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div>
                                        {
                                            !this.props.assignDate &&
                                            <IonButton
                                                fill="clear"
                                                className="organizationDetailsNewProductExpiryDateButton"
                                                onClick={() => {
                                                    this.setState({ isAssignDateModalOpen: true });
                                                }}
                                            >
                                                Set purchase date
                                            </IonButton>
                                        }
                                        {
                                            this.props.assignDate &&
                                            <p
                                                onClick={() => {
                                                    this.setState({ isAssignDateModalOpen: true });
                                                }}
                                            >
                                                Purchased on: {(new Date(this.props.assignDate * 1000)).toLocaleDateString()} {/* TO BE LOCALIZED */}
                                            </p>
                                        }
                                    </div>

                                    <div className="organizationDetailsNewProductAssignButtonDiv">
                                        {this.props.assignDate === null &&
                                            <p style={{ textAlign: 'center' }}>⚠️ Set a license purchase date ⚠️</p>
                                        }
                                        {this.state.mailerliteGroupsChanges.added.length === 0 &&
                                            this.state.mailerliteGroupsChanges.deleted.length === 0 &&
                                            <p style={{ textAlign: 'center' }}>⚠️ There were no changes to the user's groups ⚠️</p>
                                        }
                                        <IonButton
                                            // disabled={
                                            //     this.state.assignDate === null ||
                                            //     (this.state.mailerliteGroupsChanges.added.length === 0 &&
                                            //         this.state.mailerliteGroupsChanges.deleted.length === 0)
                                            // }
                                            onClick={() => {
                                                const { addedGroups, deletedGroups } = this.findArrayChanges();
                                                console.log("Added Groups:", addedGroups);
                                                console.log("Deleted Groups:", deletedGroups);
                                                let changes = {
                                                    added: addedGroups,
                                                    deleted: deletedGroups
                                                }
                                                this.setState({ mailerliteGroupsChanges: changes, showConfirmationPopover: true })
                                            }}
                                        >
                                            Save groups {/* TO BE LOCALIZED */}
                                        </IonButton>
                                    </div>
                                </IonCardContent>
                            </IonCard>
                        </div>
                        <IonLoading isOpen={this.state.isLoading} message={"Loading data..."} />
                    </div>

                    <IonModal
                        isOpen={this.state.isAssignDateModalOpen}
                        onDidDismiss={() => {
                            this.setState({ isAssignDateModalOpen: false });
                        }}
                    >
                        <IonContent>
                            <IonDatetime
                                max={this.state.fiveYearsFromNow.toISOString().split("T")[0]}
                                onIonChange={e => {
                                    this.props.updateAssignDate(e.detail.value ? Math.floor((new Date(e.detail.value)).getTime() / 1000) : null)
                                    // this.setState({ assignDate: e.detail.value ? Math.floor((new Date(e.detail.value)).getTime() / 1000) : null });
                                }}
                            />

                            <IonButton
                                onClick={() => {
                                    this.setState({ isAssignDateModalOpen: false });
                                }}
                            >
                                Done {/* TO BE LOCALIZED */}
                            </IonButton>

                        </IonContent>
                    </IonModal>
                </IonPopover >
                <IonPopover
                    className='changesPopover'
                    isOpen={this.state.showConfirmationPopover}
                    onDidDismiss={() => this.setState({ showConfirmationPopover: false })}
                >
                    <IonFab horizontal='end' vertical='top'>
                        <IonFabButton onClick={() => {
                            this.setState({ showConfirmationPopover: false })
                        }}>
                            <IonIcon
                                icon={closeOutline}
                            />
                        </IonFabButton>
                    </IonFab>
                    <div className='changesPopoverMainDiv'>
                        <p className='changesPopoverTitle'>Would you like to confirm the changes?</p>
                        <div>
                            <p className='changesPopoverSubTitle'>Mailerlite groups</p>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                        <p className='changesPopopverAddedGroupsText'>Added groups</p>
                                        {this.state.mailerliteGroupsChanges.added.map((addedGroup, i) => {
                                            return (
                                                <IonRow key={i}>
                                                    <IonLabel>{addedGroup.name}</IonLabel>
                                                </IonRow>
                                            )
                                        })}
                                    </IonCol>
                                    <IonCol>
                                        <p className='changesPopopverDeletedGroupsText'>Removed groups</p>

                                        {this.state.mailerliteGroupsChanges.deleted.map((deletedGroup, i) => {
                                            return (
                                                <IonRow key={i}>
                                                    <IonLabel>{deletedGroup.name}</IonLabel>
                                                </IonRow>
                                            )
                                        })}
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>
                        <div>
                            <p className='changesPopoverSubTitle'>Purchase date</p>
                            {this.props.assignDate &&
                                <p>Purchase date: <b>{(new Date(this.props.assignDate * 1000)).toLocaleDateString()} {/* TO BE LOCALIZED */}</b></p>
                            }
                        </div>
                        <div className='changesPopoverBtnDiv'>
                            <IonButton
                                color='success'
                                onClick={() => {
                                    this.setState({ isLoading: true })
                                    const changeMailerliteGroups = httpsCallable(functions, "changeMailerliteGroups")
                                    changeMailerliteGroups({ email: this.props.userDbInfo?.email, groupChanges: this.state.mailerliteGroupsChanges, subscriberId: this.props.mailerLiteSubscriberId, purchaseDate: this.props.assignDate ? this.props.assignDate * 1000 : null })
                                        .then((response: any) => {
                                            console.log("CHANGED MAILERLITE GROUPS: ", response);
                                            this.setState({ showConfirmationPopover: false, isLoading: false })
                                            window.location.reload()
                                        })
                                }}
                            >
                                Confirm
                            </IonButton>
                        </div>
                    </div>
                </IonPopover>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HandleMailerlitePopover);