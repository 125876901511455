import {
    IonFab,
    IonFabButton,
    IonIcon,
    IonItem,
    IonLoading,
    IonPopover
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { Plan, UserLicense, UserSubscription } from '../../licenses/store/reducers';
import { getPlanName } from '../../utils';
import "./UserSubscriptionsPopover.css";

type Props = {
    history: any,
    userId: string,
    isOpen: boolean,
    subscriptions: UserSubscription[],


    onDismiss: () => void,
};

type State = {
    isLoading: boolean,
};

class UserSubscriptionsPopover extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
        }
    }

    componentDidMount(): void {
    }

    render() {
        return (
            <IonPopover
                isOpen={this.props.isOpen}
                onDidDismiss={() => {
                    this.props.onDismiss()
                }}
                className='subscriptionsPopover'
            >
                <IonFab horizontal='end' vertical='top'>
                    <IonFabButton onClick={() => {
                        this.props.onDismiss()
                    }}>
                        <IonIcon
                            icon={closeOutline}
                        />
                    </IonFabButton>
                </IonFab>
                <div className='subscriptionsPopoverDiv'>
                    <div className='subscriptionsPopoverContentDiv'>
                        {this.props.subscriptions.length === 0 &&
                            <p>There is no subscription (yet 😉)</p>
                        }
                        {this.props.subscriptions.map(subscription => {
                            return (
                                <IonItem className='userDetailProductItem'>
                                    <div
                                        key={subscription.id}
                                        className="organizationDetailProductItemDiv"
                                    >
                                        <p className="organizationDetailProductItemName">
                                            {getPlanName(subscription.plan_id)}
                                        </p>

                                        <p>
                                            Creation date: {(new Date(subscription.created_at)).toLocaleDateString()}.
                                        </p>
                                        <p>
                                            Starting date: {(new Date(subscription.start_at)).toLocaleDateString()}.
                                        </p>
                                        <p>
                                            Ending date: {(new Date(subscription.end_at)).toLocaleDateString()}.
                                        </p>
                                    </div>
                                </IonItem>
                            )
                        })}
                    </div>
                    <IonLoading isOpen={this.state.isLoading} message={"Loading data..."} />
                </div>
            </IonPopover >
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSubscriptionsPopover);