import {
    IonBackButton, IonButton, IonButtons, IonContent, IonDatetime,
    IonGrid, IonHeader, IonInput, IonLoading, IonModal, IonPage,
    IonSelect, IonSelectOption, IonTitle, IonToolbar
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import MenuTabBar from '../../../../common/TabBar/MenuTabBar';
import { functions } from '../../../../firebaseConfig';
import "./ReasyPendingUser.css";
import { Organization } from '../../organizations/store/reducers';

type Props = {
    history: any,
};

type State = {
    newUserEmail: string | undefined,
    licenseType: number | null,
    licenseExpiryDate: number | null,

    isDateModalOpen: boolean,
    nowDate: Date,
    fiveYearsFromNow: Date,

    isLoadingOrganizations: boolean,
    orgs: Organization[] | null,
    selectedOrganization: Organization | null,

    isCreatingPendingUser: boolean,
};

class ReasyPendingUser extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        const nowDate = new Date();
        const fiveYearsFromNow = new Date(nowDate.getFullYear() + 5, nowDate.getMonth(), nowDate.getDate());

        this.state = {
            newUserEmail: undefined,
            licenseType: null,
            licenseExpiryDate: null,

            isDateModalOpen: false,
            nowDate: nowDate,
            fiveYearsFromNow: fiveYearsFromNow,

            isLoadingOrganizations: false,
            orgs: null,
            selectedOrganization: null,

            isCreatingPendingUser: false,
        }
    }

    componentDidMount(): void {
        this.setState({ isLoadingOrganizations: true });
        const getOrganizations = httpsCallable(functions, "getOrganizations");
        getOrganizations()
            .then(res => {
                if ((res.data as any).organizations) {
                    this.setState({ orgs: (res.data as any).organizations as Organization[] });
                }
                else {
                    console.error("[ReasyPendingUser] error returned from getOrganizations cloud function:", res.data);
                }
                this.setState({ isLoadingOrganizations: false });
            })
            .catch(err => {
                console.error("[ReasyPendingUser] error calling getOrganizations cloud function:", err);
                this.setState({ isLoadingOrganizations: false });
            });
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                defaultHref="/reasy"
                            />
                        </IonButtons>
                        <IonTitle>
                            Pending user creation {/* TO BE LOCALIZED */}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="reasySummaryMainGrid">
                        <IonInput
                            placeholder="User email" /* TO BE LOCALIZED */
                            type="email"
                            value={this.state.newUserEmail}
                            onIonChange={(e) => {
                                this.setState({ newUserEmail: e.detail.value ? e.detail.value.trim() : undefined });
                            }}
                        />

                        <IonSelect
                            value={this.state.selectedOrganization}
                            interface='action-sheet'
                            placeholder="Select an organization"
                            onIonChange={(e) => {
                                this.setState({ selectedOrganization: e.detail.value ? e.detail.value : null });
                            }}
                        >
                            {
                                this.state.orgs?.map(org => {
                                    return (
                                        <IonSelectOption key={org.id} value={org}>
                                            {org.name}
                                        </IonSelectOption>
                                    )
                                })
                            }
                        </IonSelect>

                        {/* <IonSelect
                            value={this.state.licenseType}
                            placeholder="Seleziona una tipologia di prodotto"
                            onIonChange={(e) => {
                                this.setState({ licenseType: e.detail.value ? e.detail.value : null });
                            }}
                        >
                            <IonSelectOption value={-1}>
                                Licenza di prova gratuita
                            </IonSelectOption>
                            <IonSelectOption value={3}>
                                Licenza gratuita assegnata dal team di T4A ✨
                            </IonSelectOption>
                            <IonSelectOption value={4}>
                                Bonifico
                            </IonSelectOption>                                                
                        </IonSelect> */}

                        {/* <div>
                            {
                                !this.state.licenseExpiryDate &&
                                <IonButton
                                    fill="clear"
                                    className="organizationDetailsNewProductExpiryDateButton"
                                    onClick={() => {
                                        this.setState({isDateModalOpen: true });
                                    }}
                                >
                                    Imposta data di scadenza del prodotto
                                </IonButton>
                            }
                            {
                                this.state.licenseExpiryDate &&
                                <p
                                    onClick={() => {
                                        this.setState({isDateModalOpen: true });
                                    }}
                                >
                                    Scadenza il: {(new Date(this.state.licenseExpiryDate*1000)).toLocaleDateString()}
                                </p>
                            }
                        </div> */}

                        <IonButton
                            disabled={
                                !this.state.newUserEmail ||
                                !this.state.selectedOrganization
                            }
                            onClick={() => {
                                const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
                                if (!emailRegex.test(this.state.newUserEmail!)) {
                                    alert('Email non corretta')
                                }
                                else {

                                    this.setState({ isCreatingPendingUser: true });
                                    httpsCallable(functions, "createReasyPendingUser")({
                                        userEmail: String(this.state.newUserEmail).trim().toLowerCase(),
                                        organizationUuid: this.state.selectedOrganization?.id,
                                    })
                                        .then(res => {
                                            this.setState({ isCreatingPendingUser: false });
                                            console.log("[ReasyPendingUser] res:", res);
                                            if ((res.data as any).error) {
                                                console.error("[ReasyPendingUser] error creating pending user:", res.data);
                                                alert("Errore della funzione: " + JSON.stringify((res.data as any).error));
                                            }
                                            else {
                                                this.setState({
                                                    newUserEmail: undefined,
                                                    // selectedOrganization: null,
                                                });
                                            }
                                        })
                                        .catch(err => {
                                            this.setState({ isCreatingPendingUser: false });
                                            console.error("[ReasyPendingUser] error calling createReasyPendingUser cloud function:", err);
                                            alert("Errore nel chiamare la funzione: " + err)
                                        });
                                }
                            }}
                        >
                            Create pending user {/* TO BE LOCALIZED */}
                        </IonButton>

                    </IonGrid>
                </IonContent>

                <IonModal
                    isOpen={this.state.isDateModalOpen}
                    onDidDismiss={() => {
                        this.setState({ isDateModalOpen: false });
                    }}
                >
                    <IonContent>
                        <IonDatetime
                            max={this.state.fiveYearsFromNow.toISOString().split("T")[0]}
                            onIonChange={e => {
                                this.setState({ licenseExpiryDate: e.detail.value ? Math.floor((new Date(e.detail.value)).getTime() / 1000) : null });
                            }}
                        />

                        <IonButton
                            onClick={() => {
                                this.setState({ isDateModalOpen: false });
                            }}
                        >
                            Done {/* TO BE LOCALIZED */}
                        </IonButton>

                    </IonContent>
                </IonModal>

                <MenuTabBar />

                <IonLoading
                    isOpen={this.state.isCreatingPendingUser}
                />
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReasyPendingUser);