import apiService from "../../../../apiService"
import { Plan } from "./reducers"

export const licensesServices = {
    getAllPlans,
}

function getAllPlans() {
    return new Promise<Plan[]>((resolve, reject) => {
        apiService.get(`/plans`)
            .then(plans => {
                console.log("[getAllPlans] got plans:", plans)
                resolve(plans as Plan[])
            })
            .catch(error => {
                console.error("[getAllPlans] ERROR getting plans:", error)
                reject(error)
            })
    })
}