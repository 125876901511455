import { IonButton, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { GoogleAuthProvider, linkWithCredential, signInWithCredential, signInWithPopup } from 'firebase/auth';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { auth, googleAuthProvider } from '../../../firebaseConfig';
import './AuthenticationLogin.css';
import { logoGoogle } from 'ionicons/icons';

type Props = {
	history: any,
	isAuthenticated: boolean,
}

type State = {
	email: string | null,
	password: string | null,
}

class AuthenticationLogin extends React.Component<Props, State> {

	constructor(props: any) {
		super(props);
		this.state = {
			email: null,
			password: null,
		}
	}

	render() {

		return (
			<IonPage>

				<IonContent fullscreen>

					<IonGrid className="authenticationLoginMainGrid">
						<IonButton
							expand="block"
							fill="outline"
							color="medium"
							mode="ios"
							className="authenticationLoginGoogleButton"
							onClick={() => {
								try {
									signInWithPopup(auth, googleAuthProvider)
										.then(result => {
											if (result.user) {
												this.props.history.replace(`/`);
											}
										})
										.catch(err => {
											console.error("[CredentialsSignup] google error authenticating:", err);
											const credential = GoogleAuthProvider.credentialFromError(err);
											console.error("[AuthGetStarted] google error authenticating. credentials:", credential);
										})
								}
								catch (error) {
									console.error("[AuthenticationLogin] google sign in with popup error:", error);
								}
							}}
						>
							<IonIcon icon={logoGoogle} slot="start" />
							Sign in with your company account {/* TO BE LOCALIZED */}
						</IonButton>
					</IonGrid>




					{this.props.isAuthenticated && <Redirect to="/reasy" />}
				</IonContent>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		isAuthenticated: state.auth.userData ? true : false,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationLogin);