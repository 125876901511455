import apiService from "../../../../apiService";
import { UserLicense, UserSubscription } from "../../licenses/store/reducers";
import { OrganizationMembership } from "../../organizations/store/reducers";
import { ReferredUser, UserDbInfo } from "../../users/store/reducers";

export const userServices = {
    getUserInfo,
    getUserReferrals,

    getUserOrganizationMemberships,

    getUserLicenses,
    getUserSubscriptions,


    addLicenseToUser,
    deleteLicenseFromUser,
}


function getUserInfo(userId: string) {
    return new Promise<UserDbInfo>((resolve, reject) => {
        apiService.get(`/users/${userId}`)
            .then(userInfo => {
                console.log("[getUserInfo] got user info:", userInfo)
                resolve(userInfo as UserDbInfo)
            })
            .catch(error => {
                console.error("[getUserInfo] ERROR getting user info:", error)
                reject(error)
            })
    })
}
function getUserReferrals(userId: string) {
    return new Promise<ReferredUser[]>((resolve, reject) => {
        apiService.get(`/users/${userId}/referrals`)
            .then((response: any) => {
                console.log("[getUserReferrals] got user referrals:", response.referred)
                resolve(response.referred)
            })
            .catch(error => {
                console.error("[getUserReferrals] ERROR getting user referrals:", error)
                reject(error)
            })
    })
}

function getUserOrganizationMemberships(userId: string) {
    return new Promise<OrganizationMembership[]>((resolve, reject) => {
        apiService.get(`/users/${userId}/organization-memberships`)
            .then((response: any) => {
                console.log("[getUserOrganizationMemberships] got user memberships:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[getUserOrganizationMemberships] ERROR getting user memberships:", error)
                reject(error)
            })
    })
}

function getUserLicenses(userId: string) {
    return new Promise<UserLicense[]>((resolve, reject) => {
        apiService.get(`/users/${userId}/licenses`)
            .then((response: any) => {
                console.log("[getUserLicenses] got user licenses:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[getUserLicenses] ERROR getting user licenses:", error)
                reject(error)
            })
    })
}
function getUserSubscriptions(userId: string) {
    return new Promise<UserSubscription[]>((resolve, reject) => {
        apiService.get(`/users/${userId}/subscriptions`)
            .then((response: any) => {
                console.log("[getUserSubscriptions] got user subscriptions:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[getUserSubscriptions] ERROR getting user subscriptions:", error)
                reject(error)
            })
    })
}

function addLicenseToUser(userId: string, plan_id: string, start_at: number, end_at: number) {
    return new Promise<UserLicense>((resolve, reject) => {
        const data = {
            plan_id: plan_id,
            start_at: start_at,
            end_at: end_at,
        }
        apiService.post(`/users/${userId}/licenses`, data)
            .then((response) => {
                console.log("[getUserLicenses] added user license:", response)
                resolve(response as UserLicense)
            })
            .catch(error => {
                console.error("[getUserLicenses] ERROR adding user license:", error)
                reject(error)
            })
    })
}
function deleteLicenseFromUser(userId: string, licenseId: string) {
    return new Promise<any>((resolve, reject) => {
        apiService.delete(`/users/${userId}/licenses/${licenseId}`)
            .then((response: any) => {
                console.log("[deleteLicenseFromUser] deleted user license:", response)
                resolve(response)
            })
            .catch(error => {
                console.error("[deleteLicenseFromUser] ERROR deleting user license:", error)
                reject(error)
            })
    })
}