import {
    IonBackButton, IonButton, IonButtons,
    IonContent,
    IonGrid, IonHeader, IonIcon,
    IonLoading,
    IonPage,
    IonSpinner, IonTitle, IonToast, IonToolbar
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import { mailOpenOutline, receiptOutline } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { auth, functions } from '../../../../firebaseConfig';
import { UserLicense, UserSubscription } from '../../licenses/store/reducers';
import { Organization, OrganizationDomain, OrganizationMembership } from '../../organizations/store/reducers';
import HandleLicensesPopover from '../components/HandleLicensesPopover';
import HandleMailerlitePopover from '../components/HandleMailerlitePopover';
import UserSubscriptionsPopover from '../components/UserSubscriptionsPopover';
import { UserDbInfo } from '../store/reducers';
import { userServices } from '../store/services';
import "./ReasyUserDetails.css";


type Props = {
    history: any,
};

type State = {
    orgData: Organization | null,
    orgDomains: OrganizationDomain[] | null,

    userDbInfo: UserDbInfo | null,
    isLoadingUserDbInfo: boolean,
    userLicenses: UserLicense[],
    showLicenseCreationModal: boolean,

    licenseType: number | null,
    licenseExpiryDate: number | null,
    nowDate: Date,
    fiveYearsFromNow: Date,
    isDateModalOpen: boolean,
    userMailerliteGroups: any[],
    tempUserMailerliteGroups: any[],
    allMailerliteGroups: any[],
    mailerliteImportantGroups: any[],
    initialTempUserMailerliteGroups: any[],
    showSecondaryGroups: boolean,
    isAssignDateModalOpen: boolean,
    assignDate: number | null,
    mailerliteGroupsChanges: {
        added: any[],
        deleted: any[]
    },
    showConfirmationPopover: boolean,
    mailerLiteSubscriberId: string,
    purchaseDate: any

    isLoading: boolean,

    passwordReset: boolean,
    newPassword: string,

    productsEditingMode: boolean,
    selecterLicenseToRemove: UserLicense | null,
    showRemovalConfirmationPopover: boolean,
    showLicenseHandlerPopover: boolean,
    orgMemberships: OrganizationMembership[],

    userSubscriptions: UserSubscription[],
    showSubscriptionListPopover: boolean

    showMailerlitePopover: boolean,

    showClipboardToast: boolean,
};

class ReasyUserDetails extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        const nowDate = new Date();
        const fiveYearsFromNow = new Date(nowDate.getFullYear() + 5, nowDate.getMonth(), nowDate.getDate());

        this.state = {
            orgData: null,
            orgDomains: null,

            userDbInfo: null,
            isLoadingUserDbInfo: false,
            userLicenses: [],
            showLicenseCreationModal: false,

            licenseType: null,
            licenseExpiryDate: null,
            nowDate: nowDate,
            fiveYearsFromNow: fiveYearsFromNow,
            isDateModalOpen: false,
            userMailerliteGroups: [],
            tempUserMailerliteGroups: [],
            allMailerliteGroups: [],
            initialTempUserMailerliteGroups: [],
            mailerliteImportantGroups: ["101107758551533469", "101107779014494163", "95481114482706184", "103811791718450364", "101272714622273189", "95481114495289101", "103109141593065084", "103817082758497879", "101272740947822470"],
            showSecondaryGroups: false,
            isAssignDateModalOpen: false,
            assignDate: null,
            mailerliteGroupsChanges: {
                added: [],
                deleted: []
            },
            showConfirmationPopover: false,
            mailerLiteSubscriberId: '',
            purchaseDate: null,

            isLoading: false,
            passwordReset: false,
            newPassword: '',

            productsEditingMode: false,
            selecterLicenseToRemove: null,
            showRemovalConfirmationPopover: false,
            showLicenseHandlerPopover: false,
            orgMemberships: [],

            userSubscriptions: [],
            showSubscriptionListPopover: false,

            showMailerlitePopover: false,

            showClipboardToast: false,
        }
    }

    componentDidMount() {
        const url = new URL(window.location.href);
        auth.onAuthStateChanged(user => {
            if (user) {
                this.setState({ isLoadingUserDbInfo: true });
                const userId = url.searchParams.get("id")
                if (userId) {
                    userServices.getUserInfo(userId)
                        .then(userInfo => {
                            // console.log("[ReasyUserDetails] users from new backend:", userInfo)
                            this.setState({
                                userDbInfo: userInfo,
                                isLoadingUserDbInfo: false,
                            });

                            userServices.getUserReferrals(userId)
                                .then(referrals => {
                                    // console.info("[ReasyUserDetails] user referrals:", referrals)
                                })
                                .catch(err => {
                                    // console.error("[ReasyUserDetails] error getting user referrals:", err)
                                })

                        })

                        .catch(err => {
                            // console.error("[ReasyUserDetails] error getting user data from backend:", err);
                            this.setState({ isLoadingUserDbInfo: false });
                        })
                    // userServices.getUserSubscriptions(userId)
                    //     .then(res => {
                    //         // console.log("[ReasyUserDetails] user subscriptions:", res)
                    //     })
                    //     .catch(err => {
                    //         // console.error("[ReasyUserDetails] error getting user subscriptions:", err);
                    //     })

                    userServices.getUserOrganizationMemberships(userId)
                        .then(res => {
                            console.log("[ReasyUserDetails] user organization memberships:", res)
                            this.setState({ orgMemberships: res })
                        })
                        .catch(err => {
                            // console.error("[ReasyUserDetails] error getting user organization memberships:", err);
                        })
                }
            }
            else {
                console.log("[ReasyUserDetails] user is not authenticated")
            }
        })


        // if (((res.data as any).user as UserDbInfo).organizationUuid) {
        //     const getOrganization = httpsCallable(functions, "getOrganization");
        //     getOrganization({
        //         organizationUuid: ((res.data as any).user as UserDbInfo).organizationUuid,
        //     })
        //         .then((res) => {
        //             if ((res.data as any).organization) {
        //                 this.setState({ orgData: (res.data as any).organization as Organization });
        //             }
        //             else {
        //                 console.error("[ReasyUserDetails] error returned by getOrganization cloud function:", res.data);
        //             }
        //         })
        //         .catch(err => {
        //             console.error("[ReasyUserDetails] error calling getOrganization cloud function:", err);
        //         })
        // }


    }

    findArrayChanges = () => {
        const initialGroups = this.state.initialTempUserMailerliteGroups;
        const currentGroups = this.state.tempUserMailerliteGroups;

        const addedGroups = currentGroups.filter(group => !initialGroups.some(initialGroup => initialGroup.id === group.id));
        const deletedGroups = initialGroups.filter(initialGroup => !currentGroups.some(group => group.id === initialGroup.id));

        console.log("Added Groups:", addedGroups);
        console.log("Deleted Groups:", deletedGroups);
        let changes = {
            added: addedGroups,
            deleted: deletedGroups
        }
        this.setState({ mailerliteGroupsChanges: changes })
        return { addedGroups, deletedGroups };
    }

    fromLanguageToFlag(language?: string): string {
        if (language) {
            if (language.startsWith("it")) {
                return "🇮🇹";
            }
            else if (language.startsWith("es")) {
                return "🇪🇸";
            }
            else if (language.startsWith("en")) {
                return "🇬🇧"
            }
            else if (language.startsWith("fr")) {
                return "🇫🇷"
            }
        }
        return "🏳️‍🌈";
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                defaultHref="/reasy"
                            />
                        </IonButtons>
                        <IonTitle>
                            User {/* TO BE LOCALIZED */}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className='userDetailsContent'>
                    <IonGrid className="userDetailsMainGrid">
                        {
                            this.state.isLoadingUserDbInfo &&
                            <div>
                                <IonSpinner />
                            </div>
                        }

                        {
                            !this.state.userDbInfo &&
                            !this.state.isLoadingUserDbInfo &&
                            <div>
                                <p>
                                    Nessun utente selezionato o utente non esistente.
                                </p>
                            </div>
                        }

                        {
                            this.state.userDbInfo &&
                            <>
                                <div className='detailsSectionDiv userInfoDiv'>
                                    <div className='sectionHeader'>
                                        User data {/* TO BE LOCALIZED */}
                                    </div>
                                    <div>
                                        <IonButton onClick={() => {
                                            if (navigator && navigator.clipboard && navigator.clipboard.writeText)
                                                return navigator.clipboard.writeText(this.state.userDbInfo!.id)
                                                    .then(res => {
                                                        this.setState({ showClipboardToast: true })
                                                    });
                                        }}>
                                            Copy user UUID
                                        </IonButton>
                                        <p>
                                            Email: <b>{this.state.userDbInfo.email}</b> {/* TO BE LOCALIZED */}
                                        </p>
                                        <p>
                                            Name: <b>{this.state.userDbInfo.first_name}</b> {/* TO BE LOCALIZED */}
                                        </p>
                                        <p>
                                            Surname: <b>{this.state.userDbInfo.last_name}</b> {/* TO BE LOCALIZED */}
                                        </p>
                                        <p>
                                            Stripe customer id: <b>{this.state.userDbInfo.stripe_customer_id ? this.state.userDbInfo.stripe_customer_id : "null"}</b> {/* TO BE LOCALIZED */}
                                        </p>
                                        <p>
                                            Referral id: <b>{this.state.userDbInfo.referral_code}</b> {/* TO BE LOCALIZED */}
                                        </p>
                                        <p>
                                            Language: {this.fromLanguageToFlag(this.state.userDbInfo.primary_language)}
                                        </p>
                                        <p>
                                            Marketing consent: <b>{this.state.userDbInfo.marketing_consent ? "✔️" : "❌"}</b> {/* TO BE LOCALIZED */}
                                        </p>
                                        {this.state.orgMemberships.length > 0 &&
                                            <p> Organization:
                                                {
                                                    this.state.orgMemberships.map(org => {
                                                        return (
                                                            <b>{org.org_info.name}</b>
                                                        )
                                                    })
                                                }
                                            </p>
                                        }
                                    </div>
                                    <IonToast
                                        message={'Copied to clipboard'}
                                        duration={1000}
                                        isOpen={this.state.showClipboardToast}
                                    />
                                    <IonButton onClick={() => {
                                        if (this.state.userDbInfo && this.state.userDbInfo.email !== null) {
                                            const updateUser = httpsCallable(functions, "updateUser");
                                            updateUser({ userEmail: this.state.userDbInfo?.email })
                                                .then((response: any) => {
                                                    console.log("[Reset user password] response: ", response);
                                                    this.setState({ passwordReset: true, newPassword: response.data.password, })
                                                })
                                                .catch((error: any) => {
                                                    console.log("[Reset user password] error: ", error);
                                                })
                                        }
                                    }}>
                                        Reset user password
                                    </IonButton>
                                    {this.state.passwordReset && this.state.newPassword.length > 0 &&
                                        <p>New password for the user: {this.state.newPassword}</p>
                                    }
                                </div>
                                <div className='detailsSectionDiv userProductsDiv'>
                                    <div className='sectionHeader'>
                                        Licenses {/* TO BE LOCALIZED */}
                                    </div>
                                    <div className='sectionContent'>
                                        <div
                                            className='sectionLicensesDiv'
                                            onClick={() => {
                                                this.setState({ isLoading: true })
                                                if (this.state.userDbInfo)
                                                    userServices.getUserLicenses(this.state.userDbInfo.id)
                                                        .then(res => {
                                                            this.setState({ userLicenses: res })
                                                            this.setState({ isLoading: false, showLicenseHandlerPopover: true })
                                                            // console.log("[ReasyUserDetails] user licenses:", res)
                                                        })
                                                        .catch(err => {
                                                            // console.error("[ReasyUserDetails] error getting user licenses:", err);
                                                        })
                                            }}
                                        >
                                            <IonIcon icon={receiptOutline} className='sectionLicensesIcon' />
                                            <p>Handle licenses</p>
                                        </div>
                                        <HandleLicensesPopover
                                            history={this.props.history}
                                            userId={this.state.userDbInfo.id}
                                            onDismiss={() => this.setState({ showLicenseHandlerPopover: false })}
                                            isOpen={this.state.showLicenseHandlerPopover}
                                            licenses={this.state.userLicenses}
                                            onLicenseAdded={(license => {
                                                let tempLicenses = this.state.userLicenses
                                                tempLicenses.push(license)
                                                this.setState({ userLicenses: tempLicenses })
                                            })}
                                            onLicenseDeleted={(licenseId => {
                                                this.setState({ userLicenses: this.state.userLicenses.filter(license => license.id !== licenseId) })
                                            })}
                                        />
                                        <div
                                            className='sectionSubscriptionsDiv'
                                            onClick={() => {
                                                this.setState({ isLoading: true })
                                                if (this.state.userDbInfo)
                                                    userServices.getUserSubscriptions(this.state.userDbInfo.id)
                                                        .then(res => {
                                                            this.setState({ userSubscriptions: res })
                                                            this.setState({ isLoading: false, showSubscriptionListPopover: true })
                                                            // console.log("[ReasyUserDetails] user licenses:", res)
                                                        })
                                                        .catch(err => {
                                                            // console.error("[ReasyUserDetails] error getting user licenses:", err);
                                                        })
                                            }}
                                        >
                                            <IonIcon icon={receiptOutline} className='sectionLicensesIcon' />
                                            <p>View subscriptions</p>
                                        </div>
                                        <UserSubscriptionsPopover
                                            history={this.props.history}
                                            userId={this.state.userDbInfo.id}
                                            onDismiss={() => this.setState({ showSubscriptionListPopover: false })}
                                            isOpen={this.state.showSubscriptionListPopover}
                                            subscriptions={this.state.userSubscriptions}
                                        />

                                    </div>
                                    {/* <LicenseRemovalModal
                                        history={this.props.history}
                                        isOpen={this.state.showRemovalConfirmationPopover}
                                        onDidDismiss={() => this.setState({ showRemovalConfirmationPopover: false, selecterLicenseToRemove: null })}
                                        license={this.state.selecterLicenseToRemove}
                                    // orgData={this.state.orgData}
                                    />
                                    <LicenseCreationModal
                                        history={this.props.history}
                                        isOpen={this.state.showLicenseCreationModal}
                                        onDidDismiss={() => this.setState({ showLicenseCreationModal: false })}
                                    /> */}
                                </div>
                                <div className='detailsSectionDiv userCommunicationDiv'>
                                    <div className='sectionHeader'>
                                        Communication {/* TO BE LOCALIZED */}
                                    </div>
                                    <div className='sectionContent'>
                                        <div className='sectionLicensesDiv' onClick={() => {
                                            if (this.state.userDbInfo) {
                                                this.setState({ isLoading: true })
                                                const getMailerliteGroups = httpsCallable(functions, "getMailerliteGroups")
                                                getMailerliteGroups({ email: this.state.userDbInfo?.email })
                                                    .then((response: any) => {
                                                        // console.log("[getMailerliteGroups] response: ", response);
                                                        if (response.data.subscriberId === '') {
                                                            this.setState({ userMailerliteGroups: response.data.userGroups, tempUserMailerliteGroups: response.data.userGroups, allMailerliteGroups: response.data.AllGroups, mailerLiteSubscriberId: response.data.subscriberId, assignDate: new Date(response.data.purchaseDate).getTime() / 1000 }, () => {
                                                                this.setState({ showMailerlitePopover: true, isLoading: false })
                                                            })
                                                        }
                                                        else {
                                                            this.setState({ userMailerliteGroups: response.data.userGroups, tempUserMailerliteGroups: response.data.userGroups, allMailerliteGroups: response.data.AllGroups, mailerLiteSubscriberId: response.data.subscriberId, assignDate: new Date(response.data.purchaseDate).getTime() / 1000 }, () => {
                                                                this.setState({
                                                                    initialTempUserMailerliteGroups: [...this.state.tempUserMailerliteGroups],
                                                                }, () => {
                                                                    this.setState({ showMailerlitePopover: true, isLoading: false })
                                                                });
                                                            })
                                                        }
                                                    })
                                            }
                                        }}>
                                            <IonIcon icon={mailOpenOutline} className='sectionLicensesIcon' />
                                            <p>Handle Mailerlite Groups</p>
                                        </div>

                                        <HandleMailerlitePopover
                                            history={this.props.history}
                                            userDbInfo={this.state.userDbInfo}
                                            userMailerliteGroups={this.state.userMailerliteGroups}
                                            tempUserMailerliteGroups={this.state.tempUserMailerliteGroups}
                                            initialTempUserMailerliteGroups={this.state.initialTempUserMailerliteGroups}

                                            allMailerliteGroups={this.state.allMailerliteGroups}
                                            mailerLiteSubscriberId={this.state.mailerLiteSubscriberId}
                                            assignDate={this.state.assignDate}
                                            isOpen={this.state.showMailerlitePopover}

                                            onDismiss={() => this.setState({ showMailerlitePopover: false })}
                                            updateAssignDate={(date) => {
                                                this.setState({ assignDate: date });
                                            }}
                                            groupClicked={(group) => {
                                                let userGroups = this.state.tempUserMailerliteGroups
                                                if (this.state.tempUserMailerliteGroups.filter(userGroup => group.id === userGroup.id).length > 0) {
                                                    /* USER IS ALREADY IN THE GROUP -> REMOVE HIM */
                                                    this.setState(prevState => {
                                                        const updatedGroups = [...prevState.tempUserMailerliteGroups];
                                                        const groupIndex = updatedGroups.findIndex(userGroup => userGroup.id === group.id);
                                                        if (groupIndex !== -1) {
                                                            updatedGroups.splice(groupIndex, 1);
                                                        }
                                                        return { tempUserMailerliteGroups: updatedGroups };
                                                    }, () => {
                                                        this.findArrayChanges()
                                                    });
                                                }
                                                else {
                                                    /* USER IS NOT IN THE CLICKED GROUP -> ADD HIM */
                                                    this.setState(prevState => {
                                                        const updatedGroups = [...prevState.tempUserMailerliteGroups];
                                                        if (!updatedGroups.some(userGroup => userGroup.id === group.id)) {
                                                            updatedGroups.push(group);
                                                        }
                                                        return { tempUserMailerliteGroups: updatedGroups };
                                                    }, () => {
                                                        this.findArrayChanges()
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        }

                    </IonGrid>
                </IonContent>

                <IonLoading isOpen={this.state.isLoading} />
            </IonPage >

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReasyUserDetails);