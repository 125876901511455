import { Product } from "./licenses/store/reducers";

// export function fromProductIdToProductName(productId: string): string {
//     switch (String(productId).trim()) {
//         case "prod_P1s70cOVfiPMjR":
//             return "Licenza web";

//         case "prod_MTWGRkJLFr6LOG":
//             return "Licenza web - TEST";

//         case "ai.tech4all.reasy.full.free-trial":
//             return "Prova gratuita"; /* TO BE LOCALIZED */

//         case "ai.tech4all.reasy.full.free":
//             return "Licenza gratuita assegnata dal team di Tech4All"; /* TO BE LOCALIZED */

//         case "ai.tech4all.reasy.full.custom":
//             return "Licenza personalizzata"; /* TO BE LOCALIZED */

//         case "ai.tech4all.reasy.full.monthly":
//             return "Abbonamento mensile"; /* TO BE LOCALIZED */

//         default:
//             break;
//     }
//     return "";
// }

// export function doesItHaveAnActiveProduct(products: Product[]): boolean {
//     const nowTimestamp = Math.floor((new Date()).getTime() / 1000);
//     for (let i = 0; i < products.length; i++) {
//         if (products[i].expiryTimestamp > nowTimestamp) {
//             return true;
//         }
//     }
//     return false;
// }

export const Demo_PlanPriceIds = [
    /* Base monthly */
    {
        plan_id: "00909a10-a68a-4863-bcd3-42d270a97690",
        stripe_price_id: "price_1PdEAUKn10j5f5DBS8kTSmMY",
        apple_price_id: "ai.tech4all.reasy.base.monthly",
        plan_name: "Reasy Base"
    },
    /* Pro monthly */
    {
        plan_id: "2a74097d-e8ba-4229-8149-a6d2861c77fc",
        stripe_price_id: "price_1LkZEDKn10j5f5DBW8SMbuy7",
        apple_price_id: "ai.tech4all.reasy.pro.monthly",
        plan_name: "Reasy Pro"
    },
    {
        plan_id: "e102b380-6163-4ca2-b990-3980a35d8c8f",
        // stripe_price_id: "price_1LkZEDKn10j5f5DBW8SMbuy7",
        // apple_price_id: "ai.tech4all.reasy.pro.monthly",
        plan_name: "Reasy Unlimited"
    },
]
export const Prod_PlanPriceIds = [
    /* Base monthly */
    {
        plan_id: "7eb8f728-eb0a-4bd2-b6ed-da1b5397396c",
        stripe_price_id: "price_1PdDdDKn10j5f5DBspcWIsTK",
        apple_price_id: "ai.tech4all.reasy.base.monthly",
        plan_name: "Reasy Base"
    },
    /* Pro monthly */
    {
        plan_id: "2792fcc2-960f-497a-910c-d12981e21ad4",
        stripe_price_id: "price_1ODoNQKn10j5f5DBJ6GIyKb7",
        apple_price_id: "ai.tech4all.reasy.pro.monthly",
        plan_name: "Reasy Pro"
    },
    {
        plan_id: "e102b380-6163-4ca2-b990-3980a35d8c8f",
        // stripe_price_id: "price_1ODoNQKn10j5f5DBJ6GIyKb7",
        // apple_price_id: "ai.tech4all.reasy.pro.monthly",
        plan_name: "Reasy Unlimited"
    },
]

/* DEMO */
/* export function getPlanName(plan_id: string) {
    return Demo_PlanPriceIds.filter(plan => plan.plan_id == plan_id)[0]?.plan_name
} */
/* PROD */
export function getPlanName(plan_id: string) {
    return Prod_PlanPriceIds.filter(plan => plan.plan_id == plan_id)[0]?.plan_name
}
